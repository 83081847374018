import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import ApiService from "../api/ApiService";
import config from "../config";
import ReactLoading from "react-loading";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { IOS_APP_URL } from "../constants";
import Default from "../layout/Default";
// const headerBlackLogo = "/images/logos/logo-black-new.png";
const headerWhiteLogo = "/images/logos/logo.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(249, 127, 102)",
    },
    secondary: {
      main: "rgb(249, 127, 102)",
    },
    white: {
      main: "rgb(255, 2255, 255)",
    },
  },
  components: {
    // Name of the component
    MuiTextField: {
      styleOverrides: {
        input: {
          // Some CSS
          fontSize: "5rem",
        },
        label: {
          // Some CSS
          fontSize: "5rem",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {},
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       color: '#fff',
    //     },
    //     notchedOutline: {
    //       borderColor: '#fff',
    //     },
    //   },
    // },
  },
});
const CustomButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  borderRadius: 30,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 10,
  paddingBottom: 10,
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  color: "#fff",
  borderRadius: 30,
  // paddingLeft: 30,
  // paddingRight: 30,
  // paddingTop: 10,
  // paddingBottom: 10,
  borderColor: "#fff",
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff",
  },
  ".MuiOutlinedInput-input": {
    color: "#fff",
  },
  " .MuiInputLabel-root": {
    color: "#fff",
  },
}));

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App(props) {
  const [loading, setLoading] = useState(false);
  const [isSuccessfull, setIsSuccessfull] = useState(false);
  const [afterData, setAfterData] = useState(false);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  let query = useQuery();
  useEffect(() => {}, []);
  const checkIfVerified = async () => {
    console.log("token", query.get("token"));
    const token = query.get("token");
    if (token) {
      if (password && confirmPassword) {
        if (password === confirmPassword) {
          const data = {
            token,
            password,
          };
          console.log("data", data);
          try {
            setLoading(true);
            const responseToken = await ApiService.resetPassword(data);
            console.log("[USER_VERIFY responseToken]", responseToken);
            setLoading(false);
            setAfterData(true);
            if (
              responseToken &&
              responseToken.data &&
              responseToken.data.passwordChanged
            ) {
              setIsSuccessfull(true);
            } else {
              setIsSuccessfull(false);
            }
          } catch (error) {
            console.log("[USER_VERIFY error]", error);
            setAfterData(true);
            setLoading(false);
            setIsSuccessfull(false);
          }
        } else {
          alert("Password and Confirm Password should match");
        }
      } else {
        alert("Please enter Password and Confirm Password");
      }
    } else {
      alert("Unauthorized request");
    }
  };

  const onChangePassword = (e) => {
    if (e.target.value && e.target.value !== "") {
      setPassword(e.target.value);
    } else {
      setPassword(null);
    }
  };
  const onChangeConfirmPassword = (e) => {
    if (e.target.value && e.target.value !== "") {
      setConfirmPassword(e.target.value);
    } else {
      setConfirmPassword(null);
    }
  };

  const goToSignUp = () => {
    window.location.href = IOS_APP_URL;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="dive-main-cont flex-1 h-full">
        <div className="main-site-wrapper-wide h-full flex-col flex">
          <Default showQRCode={false} logoSrc={headerWhiteLogo}>
            <div className="flex flex-1 flex-col">
              <article className="py-8 px-3 max-w-none lg:prose-xl dark:prose-invert p-p-text">
                <h1 className="text-center h2 py-6">Forgot Password</h1>
              </article>
              {loading ? (
                <div className="dive-details-cont flex flex-col pl-3 pr-3 justify-center items-center">
                  <div className="flex justify-center items-center h-96 flex-col ">
                    <ReactLoading
                      type={"bubbles"}
                      color={"#fff"}
                      width={50}
                      height={50}
                    />
                    <div className="dive-details-title py-5 text-center">
                      Updating your password, please wait...
                    </div>
                  </div>
                </div>
              ) : !afterData ? (
                <div className="dive-details-cont flex flex-col pl-3 pr-3 justify-center items-start max-w-2xl mx-auto">
                  <div className="flex justify-center items-start flex-col ">
                    <div className="dive-details-title pb-10 text-left">
                      Enter your new password and confirm password before
                      proceeding
                    </div>
                    <div className="pb-5 w-full">
                      <CustomTextField
                        fullWidth
                        id="standard-helperText"
                        label="New Password"
                        defaultValue=""
                        className="w-full"
                        variant="outlined"
                        color="white"
                        onChange={(e) => onChangePassword(e)}
                        type="password"
                      />
                    </div>
                    <div className="pb-5 w-full">
                      <CustomTextField
                        fullWidth
                        id="standard-helperText"
                        label="Confirm Password"
                        defaultValue=""
                        className="w-full"
                        variant="outlined"
                        color="white"
                        onChange={(e) => onChangeConfirmPassword(e)}
                        type="password"
                      />
                    </div>
                    <div className="pb-2 w-full">
                      <CustomButton
                        onClick={checkIfVerified}
                        variant="contained"
                      >
                        {loading ? (
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ReactLoading
                              type={"bubbles"}
                              color={"#fff"}
                              width={30}
                              height={30}
                            />
                            <div
                              style={{
                                paddingLeft: 5,
                              }}
                            >
                              Saving
                            </div>
                          </div>
                        ) : (
                          <div>Save</div>
                        )}
                      </CustomButton>
                    </div>
                  </div>
                </div>
              ) : isSuccessfull ? (
                <div className="dive-details-cont flex flex-col pl-3 pr-3 justify-center items-center">
                  <div className="flex justify-center items-center h-96 flex-col ">
                    <div className="h-20 w-20 mt-7 mb-7">
                      <svg
                        fill="#0DCE89"
                        version="1.1"
                        id="lni_lni-checkmark-circle"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 64 64"
                      >
                        <g>
                          <g>
                            <path
                              d="M32,62.8C15,62.8,1.3,49,1.3,32C1.3,15,15,1.3,32,1.3C49,1.3,62.8,15,62.8,32C62.8,49,49,62.8,32,62.8z M32,4.8
			C17,4.8,4.8,17,4.8,32C4.8,47,17,59.3,32,59.3C47,59.3,59.3,47,59.3,32C59.3,17,47,4.8,32,4.8z"
                            />
                          </g>
                          <g>
                            <path
                              d="M28.6,39c-0.7,0-1.3-0.2-1.9-0.7l-6.6-6.4c-0.7-0.7-0.7-1.8,0-2.5c0.7-0.7,1.8-0.7,2.5,0l6,5.9l12.8-12.4
			c0.7-0.7,1.8-0.7,2.5,0c0.7,0.7,0.7,1.8,0,2.5l-13.3,13C29.9,38.8,29.2,39,28.6,39z"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="dive-details-title pb-10 text-center">
                      Your password was changed successfully!
                    </div>
                  </div>
                </div>
              ) : (
                <div className="dive-details-cont flex flex-col pl-3 pr-3 justify-center items-center">
                  <div className="flex justify-center items-center h-96 flex-col ">
                    <div className="h-20 w-20 mt-7 mb-7">
                      <svg
                        fill="#f00"
                        version="1.1"
                        id="lni_lni-cross-circle"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 64 64"
                      >
                        <g>
                          <path
                            d="M32,1.3C15,1.3,1.3,15,1.3,32C1.3,49,15,62.8,32,62.8C49,62.8,62.8,49,62.8,32C62.8,15,49,1.3,32,1.3z M32,59.3
		C17,59.3,4.8,47,4.8,32C4.8,17,17,4.8,32,4.8C47,4.8,59.3,17,59.3,32C59.3,47,47,59.3,32,59.3z"
                          />
                          <path
                            d="M41.2,22.8c-0.7-0.7-1.8-0.7-2.5,0L32,29.5l-6.7-6.7c-0.7-0.7-1.8-0.7-2.5,0c-0.7,0.7-0.7,1.8,0,2.5l6.7,6.7l-6.7,6.7
		c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5l6.7-6.7l6.7,6.7c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5
		c0.7-0.7,0.7-1.8,0-2.5L34.5,32l6.7-6.7C41.8,24.6,41.8,23.5,41.2,22.8z"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="dive-details-title pb-10 text-center">
                      Your password request request failed, there were some
                      issues! Please try again later
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Default>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
