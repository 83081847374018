import Api from "./Api";

const ApiService = {
  async verifyEmail(token) {
    return Api.get(`/verify-email?token=${token}`);
  },
  async resetPassword(data) {
    return Api.post(`/reset-password`, data);
  },
  async getDiveDetailsFromToken(token) {
    return Api.get(`/get-dive-details-from-token?token=${token}`);
  },
  async acceptDiveValidationRequest(data) {
    return Api.post(`/approve-dive-validation-request`, data);
  },
  async rejectDiveValidationRequest(data) {
    return Api.post(`/reject-dive-validation-request`, data);
  },
  async getDiveDetailsFromId(data) {
    return Api.post(`/v1/auth/get-dive-details-from-id`, data);
  },
  async getUserDetailsFromId(data) {
    return Api.post(`/v1/auth/get-user-details-from-id`, data);
  },
  async getDiveShopDetailsFromId(data) {
    return Api.post(`/v1/auth/get-diveshop-details-from-id`, data);
  },
  async getGearDetailsFromId(id) {
    return Api.get(`v1/auth/get-gear-featured?id=${id}`);
  },
  async getBlogDetailsFromId(id) {
    return Api.get(`/v1/auth/get-buddy-blog?id=${id}`);
  },
};
export default ApiService;
