/* eslint-disable no-undef */
import React, {useEffect, useState} from "react";
import {createTheme, ThemeProvider, styled} from "@mui/material/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import ApiService from "../api/ApiService";
import config from "../config";
import ReactLoading from "react-loading";

import Default from "../layout/Default";

const headerBlackLogo = "/images/logos/logo-black-new.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(249, 127, 102)",
    },
    secondary: {
      main: "rgb(249, 127, 102)",
    },
  },
});

function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Gear(props) {
  const [loading, setLoading] = useState(false);
  const [isSuccessfull, setIsSuccessfull] = useState(false);

  const [gearDetails, setGearDetails] = useState(null);
  const [afterData, setAfterData] = useState(false);

  const [isUnauthorized, setIsUnauthorized] = useState(null);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [qrCodeData, setQrCodeData] = useState(null);

  let query = useQuery();
  let params = useParams();
  useEffect(() => {
    checkIfVerified();
    document.getElementById("user-main-cont-id").classList.add("bg-white");

    document.querySelector("body").classList.add("bg-white");
  }, []);
  const checkIfVerified = async () => {
    console.log("params", params);
    if (params && params.id) {
      setLoading(true);
      try {
        const responseOfGear = await ApiService.getGearDetailsFromId(params.id);
        setIsUnauthorized(false);
        setLoading(false);
        if (responseOfGear && responseOfGear.data) {
          // setDiveDetails(responseOfDive.data);
          setGearDetails(responseOfGear.data);
          generateBranchQRCode(responseOfGear.data);
          setIsSuccessfull(true);
        } else {
          setIsSuccessfull(false);
        }
      } catch (error) {
        setLoading(false);
        setIsUnauthorized(true);
        setIsSuccessfull(false);
      }
    } else {
      alert("Unauthorized Request");
      setLoading(false);
      setIsUnauthorized(true);
      setIsSuccessfull(false);
    }
  };

  const renderNotification = () => (
    <>
      {/* <div className="flex flex-1 py-6 px-2 flex-row notify items-center">
        <div className="flex flex-1 notify-text">
          Quin Favre is requesting you to validate this dive log
        </div>
        <div className="flex flex-row">
          <button className="btn-lite btn-lite-txt">Decline</button>

          <button className="btn-orange btn-orange-txt">Validate</button>
        </div>
      </div> */}
    </>
  );

  const handlePrevReview = () => {
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === 0 ? gearDetails.reviews.length - 1 : prevIndex - 1
    );
  };

  const handleNextReview = () => {
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === gearDetails.reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  const generateBranchQRCode = async (gearDetailsForShare) => {
    if (gearDetailsForShare && gearDetailsForShare.id) {
      const userId = gearDetailsForShare?.availableTo?.[0];
      var linkData = {
        campaign: "web share",
        feature: "gear share",
        stage: "generate deeplink",
        data: {
          $og_title: "Gear",
          $og_description: `Check out ${gearDetailsForShare?.gearName}`,
          $og_image_url: gearDetailsForShare?.gearImage,
          desktop_url: `${config.REACT_APP_SHARE_URL}gear/${gearDetailsForShare?.id}/${userId}`,
          $canonical_identifier: `gear/${gearDetailsForShare?.id}/${userId}`,
        },
      };
      branch.link(linkData, function (err, link) {
        setQrCodeData({
          type: "GEAR",
          title: `${gearDetailsForShare?.firstName}`,
          shareLink: link,
        });
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div id="user-main-cont-id" className="dive-main-cont flex-1 h-full">
        <div className="main-site-wrapper-wide h-full flex-col flex">
          <Default qrCodeData={qrCodeData} logoSrc={headerBlackLogo}>
            {renderNotification()}
            {loading ? (
              <div className="flex justify-center items-center h-96">
                <ReactLoading
                  type={"bubbles"}
                  color={"#fff"}
                  width={50}
                  height={50}
                />
              </div>
            ) : isSuccessfull && afterData ? (
              <div className="dive-details-cont flex flex-col pl-3 pr-3 justify-center items-center ">
                <div className="h-20 w-20 mt-7 mb-7">
                  <svg
                    fill="#0DCE89"
                    version="1.1"
                    id="lni_lni-checkmark-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 64 64"
                  >
                    <g>
                      <g>
                        <path
                          d="M32,62.8C15,62.8,1.3,49,1.3,32C1.3,15,15,1.3,32,1.3C49,1.3,62.8,15,62.8,32C62.8,49,49,62.8,32,62.8z M32,4.8
              C17,4.8,4.8,17,4.8,32C4.8,47,17,59.3,32,59.3C47,59.3,59.3,47,59.3,32C59.3,17,47,4.8,32,4.8z"
                        />
                      </g>
                      <g>
                        <path
                          d="M28.6,39c-0.7,0-1.3-0.2-1.9-0.7l-6.6-6.4c-0.7-0.7-0.7-1.8,0-2.5c0.7-0.7,1.8-0.7,2.5,0l6,5.9l12.8-12.4
              c0.7-0.7,1.8-0.7,2.5,0c0.7,0.7,0.7,1.8,0,2.5l-13.3,13C29.9,38.8,29.2,39,28.6,39z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="dive-details-title pb-10 border-b border-white text-center">
                  Your request is sent to Buddy Admin for validation! Thanks for
                  validating, you can now close the window
                </div>
              </div>
            ) : gearDetails && gearDetails ? (
              <div className="flex justify-center items-start flex-1 pt-10">
                <div className="gear-page flex gap-x-6 flex-col md:flex-row w-full md:max-w-[1000px] max-w-[480px] items-start justify-center">
                  {/* <div className="w-full h-full md:w-[437px] md:h-[597px]"> */}
                  <div className="w-full h-full justify-center items-center flex">
                    {gearDetails &&
                    gearDetails.gearImage &&
                    gearDetails.gearImage !== "" ? (
                      <img
                        src={gearDetails.gearImage}
                        className="border-0 rounded-xl"
                        alt="profile pic"
                      />
                    ) : (
                      <div className="gear-detail border rounded-none sm:rounded-xl sm:border-gray border-white text-left p-6">
                        <div className="text-[17px] font-basisGrotesqueMedium text-weak leading-6">
                          No image available
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="w-full h-full  md:w-[437px] md:h-[597px]"> */}
                  <div className="w-full h-full  ">
                    <div className="gear-detail border rounded-none sm:rounded-xl sm:border-gray border-white text-left p-6">
                      <div className="text-[17px] font-basisGrotesqueMedium text-weak leading-6 pb-3">
                        {gearDetails?.gearModel}
                      </div>
                      <div className="gear-name font-athleticsBold text-4xl tracking-tighter  pb-3">
                        {gearDetails?.gearName}
                      </div>
                      <div className="gear-name text-[17px] text-weak  pb-3">
                        {gearDetails?.gearDescription}
                      </div>
                      <button
                        // onClick={() => goToSignUp()}
                        className="bg-orange text-white rounded-3xl px-5 py-2 text-base w-full"
                      >
                        Buy
                      </button>
                    </div>
                    {gearDetails.reviews && gearDetails.reviews.length > 0 && (
                      <div className="gear-review border rounded-none sm:rounded-xl sm:border-gray border-white border-t-gray text-left p-6 mt-6 relative">
                        <div className="rating flex gap-3 justify-between">
                          <div className="flex gap-3">
                            <img
                              className="h-6 w-6"
                              src="/images/StarFilled.png"
                              alt="pilocation-icon"
                            />
                            <div className="text-[17px] font-basisGrotesqueMedium leading-6">
                              Rating
                            </div>
                          </div>
                          <div className="text-[17px] font-basisGrotesqueMedium leading-6">
                            {gearDetails?.averageRating}
                          </div>
                        </div>
                        <hr className="mt-3 border-gray"></hr>
                        <div className="review-section ">
                          <div className="review-title text-[13px] font-basisGrotesqueMedium pt-6">
                            Review
                          </div>
                          <div className="max-w-[432px] ">
                            {gearDetails?.reviews.length > 1 ? (
                              <Slider
                                dots={true}
                                infinite={false}
                                speed={500}
                                slidesToShow={1}
                                slidesToScroll={1}
                              >
                                {gearDetails?.reviews.map((review, index) => (
                                  <div key={index}>
                                    <div className="carousel-controls   ">
                                      <div className="review-data bg-lightGray p-6 rounded-xl mt-3 text-[15px] text-weak">
                                        <div className="">
                                          {review?.reviewText}
                                        </div>
                                      </div>

                                      <div className="review-user flex items-center py-3 px-6 mt-3">
                                        <div className="pr-3">
                                          <img
                                            className="h-8 w-8 rounded-full"
                                            src={review?.user?.profilePic}
                                            alt="user-icon"
                                          />
                                        </div>
                                        <div className="userName">
                                          <div className="text-[17px]">
                                            {review?.user?.firstName}{" "}
                                            {review?.user?.lastName}
                                          </div>
                                          <div className="text-[13px] text-weak">
                                            {review?.user?.typeOfDiver}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Slider>
                            ) : (
                              <div key={1}>
                                <div className="carousel-controls   ">
                                  <div className="review-data bg-lightGray p-6 rounded-xl mt-3 text-[15px] text-weak">
                                    <div className="">
                                      {gearDetails?.reviews[0].reviewText}
                                    </div>
                                  </div>

                                  <div className="review-user flex items-center py-3 px-6 mt-3">
                                    <div className="pr-3">
                                      <img
                                        className="h-8 w-8 rounded-full"
                                        src={
                                          gearDetails?.reviews[0].user
                                            .profilePic
                                        }
                                        alt="user-icon"
                                      />
                                    </div>
                                    <div className="userName">
                                      <div className="text-[17px]">
                                        {gearDetails?.reviews[0].user.firstName}{" "}
                                        {gearDetails?.reviews[0].user.lastName}
                                      </div>
                                      <div className="text-[13px] text-weak">
                                        {
                                          gearDetails?.reviews[0].user
                                            .typeOfDiver
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </Default>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Gear;
