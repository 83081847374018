import { IOS_APP_URL } from "../constants";

function App(props) {
  const goToSignUp = () => {
    window.location.href = IOS_APP_URL;
  };
  return (
    <div className="privacy-policy-container">
      <div className="main-site-wrapper-wide">
        <div className="dive-header-cont flex flex-row justify-between items-center pl-3 pr-3 border-b">
          <div className="dive-header-logo-cont">
            <img
              className="dive-header-logo-img"
              src="/images/logos/logo.png"
            />
          </div>
          <div className="dive-header-btn-cont">
            <button onClick={() => goToSignUp()} className="btn">
              Sign up
            </button>
          </div>
        </div>
        <div className="main-text-wrapper text-white text-left">
          <article className="prose py-8 px-3 max-w-none lg:prose-xl dark:prose-invert p-p-text">
            <h2 className="text-center h2">Terms & Conditions</h2>
            <p>Last updated: 2020-11-06 11:35:26</p>
            <p>
              We value your privacy very highly. Please read this Privacy Policy
              carefully before using the www.theloremipsumco.com Website (the
              "Website") operated by The Lorem Ipsum Company, a(n) Corporation
              formed in California, United States ("us," "we," "our") as this
              Privacy Policy contains important information regarding your
              privacy and how we may use the information we collect about you.
            </p>

            <p>
              Your access to and use of the Website is conditional upon your
              acceptance of and compliance with this Privacy Policy. This
              Privacy Policy applies to everyone, including, but not limited to:
              visitors, users, and others, who wish to access or use the
              Website.
            </p>

            <p>
              By accessing or using the Website, you agree to be bound by this
              Privacy Policy. If you disagree with any part of the Privacy
              Policy, then you do not have our permission to access or use the
              Website.
            </p>

            <p>
              We collect any and all information that you enter on this Website.
              We collect the following information about you:
            </p>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Category of information</th>
                    <th>Specific piece of information</th>
                  </tr>
                </thead>
                <tbody>
                  <tr data-heading="Identifying information - Name">
                    <td>Identifying information</td>
                    <td>Name</td>
                  </tr>
                  <tr data-heading="Identifying information - Phone number">
                    <td>Identifying information</td>
                    <td>Phone number</td>
                  </tr>
                  <tr data-heading="Identifying information - IP address">
                    <td>Identifying information</td>
                    <td>IP address</td>
                  </tr>
                  <tr data-heading="Identifying information - Email address">
                    <td>Identifying information</td>
                    <td>Email address</td>
                  </tr>
                  <tr data-heading="Internet or other electronic activity - Browsing history">
                    <td>Internet or other electronic activity</td>
                    <td>Browsing history</td>
                  </tr>
                  <tr data-heading="Internet or other electronic activity - Search history">
                    <td>Internet or other electronic activity</td>
                    <td>Search history</td>
                  </tr>
                  <tr data-heading="Internet or other electronic activity - Information regarding your interaction with our website or application">
                    <td>Internet or other electronic activity</td>
                    <td>
                      Information regarding your interaction with our website or
                      application
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2 className="text-center h2">How we may use your information</h2>
            <p>
              We may use the information that you provide us for the following:{" "}
            </p>
            <ul>
              <li>Marketing and advertising;</li>
              <li>Participation in surveys and contests;</li>
              <li>Performing services;</li>
              <li>Providing customer service;</li>
              <li>Resolving disputes;</li>
              <li>Verifying customer information;</li>
              <li>Analytics.</li>
            </ul>

            <h2 className="text-center h2">
              With whom we share your information
            </h2>
            <p>
              We share your personal information with the following categories
              of third parties:{" "}
            </p>
            <ul>
              <li>Content management systems;</li>
              <li>Customer management systems;</li>
              <li>Email marketing vendors;</li>
              <li>Parties that need to operate the website;</li>
              <li>Subsidiaries;</li>
              <li>Data analytics providers;</li>
              <li>Social networks.</li>
            </ul>

            <h2 className="text-center h2">Sale of your information</h2>
            <p>We do not sell your personal information.</p>

            <h2 className="text-center h2">Cookies</h2>
            <p>
              A cookie is a small piece of data sent from a website and stored
              on your computer by your web browser. The file is added once you
              agree to store cookies on your computer or device, and the cookie
              helps analyze web traffic or lets you know when you visit a
              particular site. Cookies allow sites to respond to you as an
              individual. The Website can also tailor its operations to your
              needs, likes, and dislikes by gathering and remembering
              information about your preferences.{" "}
            </p>

            <p>
              This Website collects cookies and may use cookies for reasons
              including, but not limited to:{" "}
            </p>
            <ul>
              <li>Analyze our web traffic using an analytics package;</li>
              <li>Identify if you are signed in to the Website;</li>
              <li>Test content on the Website;</li>
              <li>Store information about your preferences;</li>
              <li>Recognize when you return to the Website.</li>
            </ul>

            <p>
              Overall, cookies help us provide you with a better Website, by
              enabling us to monitor which pages you find useful and which you
              do not. A cookie in no way gives us access to your computer or any
              information about you, other than the data you choose to share
              with us.
            </p>

            <p>
              You can accept or decline cookies. Most web browsers automatically
              accept cookies, but you can modify your browser setting to decline
              cookies if you prefer. This setting may prevent you from taking
              full advantage of the Website.
            </p>

            <h2 className="text-center h2">Children's privacy</h2>

            <p>
              This Website is intended for use by a general audience and does
              not offer services to children. Should a child whom we know to be
              under 18 send personal information to us, we will use that
              information only to respond to that child to inform him or her
              that they cannot use this Website.
            </p>

            <h2 className="text-center h2">Analytics programs</h2>

            <p>
              This Website uses Google Analytics to collect information about
              you and your behaviors. If you would like to opt out of Google
              Analytics, please visit{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout/"
                target="_blank"
              >
                https://tools.google.com/dlpage/gaoptout/
              </a>
              .{" "}
            </p>

            <h2 className="text-center h2">Third-party websites</h2>

            <p>
              This Website may contain hyperlinks to websites operated by
              parties other than us. We provide such hyperlinks for your
              reference only. We do not control such websites and are not
              responsible for their contents or the privacy or other practices
              of such websites. It is up to you to read and fully understand
              their Privacy Policies. Our inclusion of hyperlinks to such
              websites does not imply any endorsement of the material on such
              websites or any association with their operators.{" "}
            </p>

            <h2 className="text-center h2">Do Not Track</h2>

            <p>
              Do Not Track is a preference you can set on your browser to inform
              websites that you do not want to be tracked. We do not support Do
              Not Track ("DNT"). You can either enable or disable Do Not Track
              by visiting the Preferences or Settings page of your browser.
            </p>

            <h2 className="text-center h2">Changes to Privacy Policy</h2>

            <p>
              We reserve the right to amend this Privacy Policy at any time. We
              will notify you of any changes to this Privacy Policy by posting
              the updated privacy policy to this website.{" "}
            </p>

            <h2 className="text-center h2">Questions</h2>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at info@theloremipsumco.com.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
}

export default App;
