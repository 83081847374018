/* eslint-disable no-undef */
import React, {useEffect, useState} from "react";
import {createTheme, ThemeProvider, styled} from "@mui/material/styles";
import {makeStyles} from "@mui/styles";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import ApiService from "../api/ApiService";
import config from "../config";
import ReactLoading from "react-loading";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import Button from "@mui/material/Button";
import {compose, withProps, withStateHandlers} from "recompose";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Default from "../layout/Default";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(249, 127, 102)",
    },
    secondary: {
      main: "rgb(249, 127, 102)",
    },
  },
});
const CustomButton = styled(Button)(({theme}) => ({
  color: "#fff",
  borderRadius: 30,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 10,
  paddingBottom: 10,
  textTransform: "none",
}));
const CustomButtonApprove = styled(Button)(({theme}) => ({
  color: "#fff",
  borderRadius: 30,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 10,
  paddingBottom: 10,
  backgroundColor: "#4CAF50",
}));

function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function User(props) {
  const [loading, setLoading] = useState(false);
  const [isSuccessfull, setIsSuccessfull] = useState(false);
  const [diveDetails, setDiveDetails] = useState(null);
  const [diveShopDetails, setDiveShopDetails] = useState(null);
  const [afterData, setAfterData] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [diveCenter, setDiveCenter] = useState("");
  const [diveMaster, setDiveMaster] = useState("");
  const [diveProfessionalId, setDiveProfessionalId] = useState("");
  const [isUnauthorized, setIsUnauthorized] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showFullBio, setShowFullBio] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);

  const headerBlackLogo = "/images/logos/logo-black-new.png";

  let query = useQuery();
  let params = useParams();
  useEffect(() => {
    checkIfVerified();
    document.getElementById("user-main-cont-id").classList.add("bg-white");

    document.querySelector("body").classList.add("bg-white");
  }, []);
  const checkIfVerified = async () => {
    console.log("params", params);
    if (params && params.id) {
      setLoading(true);
      try {
        const responseOfDiveShop = await ApiService.getDiveShopDetailsFromId({
          diveShopId: params.id,
        });
        console.log("responseOfDiveShop", responseOfDiveShop);
        setIsUnauthorized(false);
        setLoading(false);
        if (responseOfDiveShop && responseOfDiveShop.data) {
          // setDiveDetails(responseOfDiveShop.data);
          setDiveShopDetails(responseOfDiveShop.data);
          generateBranchQRCode(responseOfDiveShop.data);
          setIsSuccessfull(true);
        } else {
          setIsSuccessfull(false);
        }
      } catch (error) {
        setLoading(false);
        setIsUnauthorized(true);
        setIsSuccessfull(false);
      }
    } else {
      alert("Unauthorized Request");
      setLoading(false);
      setIsUnauthorized(true);
      setIsSuccessfull(false);
    }
  };

  const renderNotification = () => (
    <>
      {/* <div className="flex flex-1 py-6 px-2 flex-row notify items-center">
        <div className="flex flex-1 notify-text">
          Quin Favre is requesting you to validate this dive log
        </div>
        <div className="flex flex-row">
          <button className="btn-lite btn-lite-txt">Decline</button>

          <button className="btn-orange btn-orange-txt">Validate</button>
        </div>
      </div> */}
    </>
  );

  const showMore = () => {
    setShowFullBio(true);
  };

  const generateBranchQRCode = async (diveShopDetailsForShare) => {
    if (diveShopDetailsForShare && diveShopDetailsForShare.id) {
      var linkData = {
        campaign: "web share",
        feature: "dive shop share",
        stage: "generate deeplink",
        data: {
          $og_title: "Dive Shop",
          $og_description: `Check out ${diveShopDetailsForShare.name}\nFor full view download the app on App Store and Play Store for free.`,
          $og_image_url: diveShopDetailsForShare?.image,
          desktop_url: `${config.REACT_APP_SHARE_URL}diveshop/${diveShopDetailsForShare.id}`,
          $canonical_identifier: `diveshop/${diveShopDetailsForShare.id}`,
        },
      };
      branch.link(linkData, function (err, link) {
        setQrCodeData({
          type: "DIVE_SHOP",
          title: `${diveShopDetailsForShare.name}`,
          shareLink: link,
        });
      });
    }
  };

  const renderBio = () => {
    if (!diveShopDetails || !diveShopDetails.description) return null;
    const bio = diveShopDetails.description;
    if (showFullBio) {
      return bio;
    } else {
      const maxLength = 150;
      return bio.length > maxLength ? bio.slice(0, maxLength) + "..." : bio;
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <div id="user-main-cont-id" className="dive-main-cont flex-1 h-full">
        <div className="main-site-wrapper-wide h-full flex-col flex">
          <Default qrCodeData={qrCodeData} logoSrc={headerBlackLogo}>
            {renderNotification()}
            {loading ? (
              <div className="flex justify-center items-center h-96">
                <ReactLoading
                  type={"bubbles"}
                  color={"#fff"}
                  width={50}
                  height={50}
                />
              </div>
            ) : isSuccessfull && afterData ? (
              <div className="dive-details-cont flex flex-col pl-3 pr-3 justify-center items-center ">
                <div className="h-20 w-20 mt-7 mb-7">
                  <svg
                    fill="#0DCE89"
                    version="1.1"
                    id="lni_lni-checkmark-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 64 64"
                  >
                    <g>
                      <g>
                        <path
                          d="M32,62.8C15,62.8,1.3,49,1.3,32C1.3,15,15,1.3,32,1.3C49,1.3,62.8,15,62.8,32C62.8,49,49,62.8,32,62.8z M32,4.8
              C17,4.8,4.8,17,4.8,32C4.8,47,17,59.3,32,59.3C47,59.3,59.3,47,59.3,32C59.3,17,47,4.8,32,4.8z"
                        />
                      </g>
                      <g>
                        <path
                          d="M28.6,39c-0.7,0-1.3-0.2-1.9-0.7l-6.6-6.4c-0.7-0.7-0.7-1.8,0-2.5c0.7-0.7,1.8-0.7,2.5,0l6,5.9l12.8-12.4
              c0.7-0.7,1.8-0.7,2.5,0c0.7,0.7,0.7,1.8,0,2.5l-13.3,13C29.9,38.8,29.2,39,28.6,39z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="dive-details-title pb-10 border-b border-white text-center">
                  Your request is sent to Buddy Admin for validation! Thanks for
                  validating, you can now close the window
                </div>
              </div>
            ) : diveShopDetails && diveShopDetails ? (
              <div className="dive-details-cont flex flex-col pl-3 pr-3 flex-1">
                {/* === */}
                <div className="dive-shop-detail flex  flex-col justify-center items-center flex-1  ">
                  <div className="user-details-data m-10">
                    <div className="user-detail-profile-pic">
                      {console.log("diveShopDetails==>", diveShopDetails)}
                      {diveShopDetails &&
                      diveShopDetails.image &&
                      diveShopDetails.image !== "" ? (
                        <img
                          src={diveShopDetails.image}
                          className=" dive-details-top-img rounded-full  h-20 w-20 m-auto"
                          alt="profile pic"
                        />
                      ) : (
                        <img
                          src="/images/scubaDiverDefault.png"
                          className="mt-1 dive-details-top-img rounded-full h-20 w-20 m-auto"
                          alt="profile pic"
                        />
                      )}
                    </div>
                    <div className="user-detail-profile-name user-detail-txt font-bold  text-2xl">
                      {diveShopDetails ? (
                        <>
                          <div className="dive-details-top-title-cont py-6 ">
                            <div className="user-details-top-title text-black font-athleticsBold text-4xl">
                              {diveShopDetails?.name}
                            </div>
                            <div className="user-details-diverLevel text-black font-basisGrotesqueMedium font-medium text-[17px] leading-6">
                              {diveShopDetails?.subtitle}
                            </div>
                          </div>
                          <div className="user-bio text-weak sm:w-full md:w-[438px]  text-[15px] leading-5">
                            {renderBio()}
                            <div>
                              {!showFullBio &&
                                diveShopDetails?.description.length > 100 && (
                                  <button
                                    className="text-blue cursor-pointer"
                                    onClick={showMore}
                                  >
                                    <div>Show more</div>
                                  </button>
                                )}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    {/* <div className="user-detail-logo user-detail-txt pt-28">
                      <img
                        className="user-detail-logo-img-white h-10 m-auto"
                        src="/images/logos/logo.png"
                        alt="logo"
                      />
                    </div> */}
                  </div>
                  <div className="loc-diveDetails flex  flex-col gap-6 md:flex-row w-full justify-center   md:max-w-[1000px] max-w-[480px] ">
                    <div className="map-details-sub md:h-full !h-[284px] w-full md:w-[437px]">
                      {diveShopDetails &&
                      diveShopDetails.lat &&
                      diveShopDetails.lng ? (
                        <div className="h-full">
                          <div className="relative h-full">
                            <div className="absolute w-full top-0 left-0 h-full z-20" />
                            <MyMapComponent
                              currLocInfo={{
                                lat: parseFloat(diveShopDetails?.lat),
                                lng: parseFloat(diveShopDetails?.lng),
                              }}
                              className="rounded-xl h-full"
                              // mapRef={mapRef}
                              isMarkerShown
                            />
                            <div className="absolute p-3 flex flex-row w-full bottom-0 left-0">
                              <div className="dive-details-location-abs-left dive-details-location-abs-cont backdrop-blur-sm w-full p-3 rounded-xl flex flex-row">
                                <img
                                  className="h-8"
                                  src="/images/pinnormal.png"
                                  alt="pin"
                                />
                                <div className="flex flex-col flex-1 justify-center items-start pl-2">
                                  <div className="dive-details-location-title">
                                    {diveShopDetails?.location}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="border bottom-2 rounded-xl !h-[284px]  w-full md:w-[437px] border-gray">
                      {diveShopDetails?.location && (
                        <div className="location flex py-4 px-6 gap-3">
                          <img
                            className="h-6 w-6"
                            src="/images/location.png"
                            alt="pilocation-icon"
                          />
                          <div className="text-[17px] font-basisGrotesqueMedium leading-6">
                            {diveShopDetails?.location}
                          </div>
                        </div>
                      )}
                      <hr className="mx-6  border-gray"></hr>
                      {diveShopDetails?.phone && (
                        <div className="phone flex py-4 px-6 gap-3">
                          <img
                            className="h-6 w-6"
                            src="/images/Phone.png"
                            alt="phone-icon"
                          />
                          <a
                            href={`tel:${diveShopDetails?.phone}`}
                            className="text-[17px] font-basisGrotesqueMedium leading-6"
                          >
                            {diveShopDetails?.phone}
                          </a>
                        </div>
                      )}
                      <hr className="mx-6  border-gray"></hr>
                      {diveShopDetails?.websiteConnectLink && (
                        <div className="Globe flex py-4 px-6 gap-3">
                          <img
                            className="h-6 w-6"
                            src="/images/Globe.png"
                            alt="Globe-icon"
                          />
                          <a
                            href={diveShopDetails?.websiteConnectLink}
                            target="_blank"
                            className="text-[17px] font-basisGrotesqueMedium leading-6"
                          >
                            {diveShopDetails?.websiteConnectLink}
                          </a>
                        </div>
                      )}
                      <hr className="mx-6  border-gray"></hr>
                      {diveShopDetails?.email && (
                        <div className="inbox flex py-4 px-6 gap-3">
                          <img
                            className="h-6 w-6"
                            src="/images/Inbox.png"
                            alt="inbox-icon"
                          />
                          <a
                            href={`mailto:${diveShopDetails?.email}`}
                            className="text-[17px] font-basisGrotesqueMedium leading-6"
                          >
                            {diveShopDetails?.email}
                          </a>
                        </div>
                      )}
                      <hr className="mx-6  border-gray"></hr>
                      {diveShopDetails?.language && (
                        <div className="Language flex py-4 px-6 gap-3">
                          <img
                            className="h-6 w-6"
                            src="/images/Language.png"
                            alt="Language-icon"
                          />
                          <div className="text-[17px] font-basisGrotesqueMedium leading-6">
                            {diveShopDetails?.language &&
                            Array.isArray(diveShopDetails?.language) &&
                            diveShopDetails?.language.length > 0
                              ? diveShopDetails?.language.join(", ")
                              : diveShopDetails?.language}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* === */}
              </div>
            ) : null}

            <div></div>
          </Default>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default User;

const MyMapComponent = compose(
  withStateHandlers(
    () => ({
      // isMarkerShown: false,
      markerPosition: null,
    }),
    {
      onMapClick:
        ({}) =>
        (e) => ({
          // markerPosition: e.latLng,
          // isMarkerShown: true
        }),
    }
  ),
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAgG4PP0uGkL7sW17Upnz3HAKuF0NCiOf4&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{height: `100%`}} />,
    containerElement: (
      <div
        style={{
          height: `100%`,
          width: "100%",
          borderRadius: 18,
          overflow: "hidden",
        }}
        className="map-view"
      />
    ),
    mapElement: <div style={{height: `100%`}} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  let {
    currLocInfo,
    isMarkerShown,
    mapRef,
    setUpdateLocation,
    onMapClick,
    markerPosition,
    updateLocation,
    className,
  } = props;
  useEffect(() => {
    // console.log(markerPosition && markerPosition.lat());
    if (markerPosition) {
      setUpdateLocation({
        ...updateLocation,
        lat: markerPosition.lat(),
        lng: markerPosition.lng(),
      });
    }
  }, [markerPosition]);
  return (
    <GoogleMap
      options={{disableDefaultUI: true}}
      ref={mapRef}
      className={className}
      mapTypeId="satellite"
      defaultZoom={15}
      disableDefaultUi={true}
      defaultCenter={{
        lat: parseFloat(currLocInfo.lat),
        lng: parseFloat(currLocInfo.lng),
      }}
      // onClick={(ev) => {
      //   // console.log(mapRef.getBounds());
      //   // console.log('latitide = ', ev.latLng);
      //   // console.log('longitude = ', ev.latLng.lng());
      //   }}
      onClick={onMapClick}
    >
      {isMarkerShown && currLocInfo && (
        <>
          <Marker
            icon={{
              url: "/images/pinnormal.png",
              scaledSize: {width: 35, height: 40},
            }}
            position={{
              lat: parseFloat(currLocInfo.lat),
              lng: parseFloat(currLocInfo.lng),
            }}
          />
          {/* {markerPosition && (
            <Marker
              icon={{
                url: '/images/pinnormal.png',
                scaledSize: { width: 35, height: 40 },
              }}
              position={markerPosition}
            />
          )} */}
        </>
      )}
    </GoogleMap>
  );
});
