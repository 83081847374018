import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import ApiService from "../api/ApiService";
import config from "../config";
import ReactLoading from "react-loading";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import Button from "@mui/material/Button";
import { compose, withProps, withStateHandlers } from "recompose";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Default from "../layout/Default";
const headerBlackLogo = "/images/logos/logo-black-new.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(249, 127, 102)",
    },
    secondary: {
      main: "rgb(249, 127, 102)",
    },
  },
});
const CustomButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  borderRadius: 30,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 10,
  paddingBottom: 10,
  textTransform: "none",
}));
const CustomButtonApprove = styled(Button)(({ theme }) => ({
  color: "#fff",
  borderRadius: 30,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 10,
  paddingBottom: 10,
  backgroundColor: "#4CAF50",
}));

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App(props) {
  const [loading, setLoading] = useState(false);
  const [isSuccessfull, setIsSuccessfull] = useState(false);
  const [diveDetails, setDiveDetails] = useState(null);
  const [afterData, setAfterData] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [diveCenter, setDiveCenter] = useState("");
  const [diveMaster, setDiveMaster] = useState("");
  const [diveProfessionalId, setDiveProfessionalId] = useState("");
  const approveDive = async () => {
    try {
      if (diveDetails.diveValidationRequest.id) {
        if (
          diveCenter &&
          diveCenter !== "" &&
          diveMaster &&
          diveMaster !== "" &&
          diveProfessionalId &&
          diveProfessionalId !== ""
        ) {
          const token = query.get("token");
          setIsSuccessfull(false);
          setIsApproveLoading(true);
          const data = {
            diveValidationRequestId: diveDetails.diveValidationRequest.id,
            token: token,
            diveCenter,
            diveMaster,
            diveProfessionalId,
          };
          const response = await ApiService.acceptDiveValidationRequest(data);
          setIsApproveLoading(false);
          if (response) {
            setIsSuccessfull(true);
            setAfterData(true);
          } else {
            throw "ERROR";
          }
        } else {
          alert("Please enter all details before proceeding");
        }
      } else {
        throw "FAILED_TO_VALIDATE";
      }
    } catch (error) {
      setAfterData(true);
      setIsSuccessfull(false);
      setIsApproveLoading(false);
      console.log("[ERROR_VALID error]", error);
      alert("Failed to validate");
    }
  };

  const rejectDive = async () => {
    try {
      if (diveDetails.diveValidationRequest.id) {
        if (
          diveCenter &&
          diveCenter !== "" &&
          diveMaster &&
          diveMaster !== "" &&
          diveProfessionalId &&
          diveProfessionalId !== ""
        ) {
          const token = query.get("token");
          setIsSuccessfull(false);
          setIsRejectLoading(true);
          const data = {
            diveValidationRequestId: diveDetails.diveValidationRequest.id,
            token: token,
            diveCenter,
            diveMaster,
            diveProfessionalId,
          };
          const response = await ApiService.rejectDiveValidationRequest(data);
          setIsRejectLoading(false);
          if (response) {
            setIsSuccessfull(true);
            setAfterData(true);
          } else {
            throw "ERROR";
          }
        } else {
          alert("Please enter all details before proceeding");
        }
      } else {
        throw "FAILED_TO_VALIDATE";
      }
    } catch (error) {
      setAfterData(true);
      setIsSuccessfull(false);
      setIsRejectLoading(false);
      alert("Failed to validate");
    }
  };
  const [isUnauthorized, setIsUnauthorized] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  let query = useQuery();
  useEffect(() => {
    checkIfVerified();
    document.getElementById("dive-main-cont-id").classList.add("bg-white");
    document.querySelector("body").classList.add("bg-white");
  }, []);
  const checkIfVerified = async () => {
    console.log("token", query.get("token"));
    const token = query.get("token");
    setLoading(true);
    try {
      if (token) {
        const responseOfDive = await ApiService.getDiveDetailsFromToken(token);
        setIsUnauthorized(false);
        setLoading(false);
        if (responseOfDive && responseOfDive.data) {
          if (responseOfDive.data.verified === false) {
            setIsSuccessfull(false);
          } else {
            setDiveDetails(responseOfDive.data);
            setIsSuccessfull(true);
          }
        } else {
          setIsSuccessfull(false);
        }
      } else {
        setLoading(false);
        setIsUnauthorized(true);
      }
    } catch (error) {
      setLoading(false);
      setIsUnauthorized(true);
      setIsSuccessfull(false);
    }
  };

  const renderNotification = () => (
    <>
      <div className="flex flex-1 py-6 px-2 flex-row text-left notify">
        <div className="flex flex-1 dive-details-date media-dive-date">
          {diveDetails && diveDetails.user
            ? `${diveDetails.user.firstName} ${diveDetails.user.lastName} is requesting you to validate their dive log`
            : null}
        </div>
        {/* <div className="flex flex-row">
          <button className="btn-lite btn-lite-txt">Decline</button>

          <button className="btn-orange btn-orange-txt">Validate</button>
        </div> */}
      </div>
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <div id="dive-main-cont-id" className="dive-main-cont flex-1 h-full">
        <div className="main-site-wrapper-wide h-full flex-col flex">
          <Default showQRCode={false} logoSrc={headerBlackLogo}>
            <div className="flex flex-col flex-1">
              {/* <div className="dive-header-cont flex flex-row justify-between items-center pl-3 pr-3 border-b">
              <div className="dive-header-logo-cont">
                <img
                  className="dive-header-logo-img"
                  src="/images/logos/logo.png"
                />
              </div>
              <div className="dive-header-btn-cont">
                <button className="btn">Sign up</button>
              </div>
            </div> */}
              {loading ? (
                <div className="flex justify-center items-center h-96">
                  <ReactLoading
                    type={"bubbles"}
                    color={"#fff"}
                    width={50}
                    height={50}
                  />
                </div>
              ) : isSuccessfull && afterData ? (
                <div className="dive-details-cont flex flex-col pl-3 pr-3 justify-center items-center">
                  <div className="h-20 w-20 mt-7 mb-7">
                    <svg
                      fill="#0DCE89"
                      version="1.1"
                      id="lni_lni-checkmark-circle"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 64 64"
                    >
                      <g>
                        <g>
                          <path
                            d="M32,62.8C15,62.8,1.3,49,1.3,32C1.3,15,15,1.3,32,1.3C49,1.3,62.8,15,62.8,32C62.8,49,49,62.8,32,62.8z M32,4.8
			C17,4.8,4.8,17,4.8,32C4.8,47,17,59.3,32,59.3C47,59.3,59.3,47,59.3,32C59.3,17,47,4.8,32,4.8z"
                          />
                        </g>
                        <g>
                          <path
                            d="M28.6,39c-0.7,0-1.3-0.2-1.9-0.7l-6.6-6.4c-0.7-0.7-0.7-1.8,0-2.5c0.7-0.7,1.8-0.7,2.5,0l6,5.9l12.8-12.4
			c0.7-0.7,1.8-0.7,2.5,0c0.7,0.7,0.7,1.8,0,2.5l-13.3,13C29.9,38.8,29.2,39,28.6,39z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="dive-details-title pb-10 border-b border-white text-center">
                    Your request is sent to Buddy Admin for validation! Thanks
                    for validating, you can now close the window
                  </div>
                </div>
              ) : diveDetails && diveDetails.scubaDive ? (
                <div className="dive-details-cont flex flex-col pl-3 pr-3">
                  <div className="dive-details-title pt-10 pb-10 border-b border-white text-left block md:hidden">
                    {diveDetails.user
                      ? `${diveDetails.user.firstName} ${diveDetails.user.lastName} is requesting you to validate their dive log`
                      : null}
                  </div>
                  <div className="dive-details-top-outer flex-col sm:flex-row flex">
                    <div className="dive-details-top-outer-left flex-col w-full sm:w-1/2 md:w-2/3">
                      <div className="dive-details-top pt-8 pr-3 pl-3 flex flex-row items-start">
                        <div className="dive-details-top-left flex-1 flex flex-col justify-start items-start">
                          <div className="flex flex-row">
                            {diveDetails.user &&
                            diveDetails.user.profilePic &&
                            diveDetails.user.profilePic !== "" ? (
                              <img
                                src={diveDetails.user.profilePic}
                                className="mt-1 dive-details-top-img h-8 w-8 rounded-full"
                              />
                            ) : (
                              <img
                                src="/images/scubaDiverDefault.png"
                                className="mt-1 dive-details-top-img h-8 w-8 rounded-full"
                              />
                            )}
                            {diveDetails.user ? (
                              <div className="dive-details-top-title-cont text-left pl-3 flex flex-col">
                                <div className="dive-details-top-title media-details-top-title">
                                  {diveDetails.user.firstName}{" "}
                                  {diveDetails.user.lastName}
                                </div>
                                {diveDetails.user.diverLevel &&
                                diveDetails.user.diverLevel !== "" ? (
                                  <div className="dive-details-top-subtitle media-details-top-subtitle">
                                    {diveDetails.user.diverLevel}
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                          {diveDetails && diveDetails.scubaDive ? (
                            <div className="flex flex-col pt-3">
                              <div className="dive-details-date media-dive-date">
                                {moment(diveDetails.scubaDive.date).format(
                                  "MMMM DD, yyyy"
                                )}
                              </div>
                              <div className="dive-details-shop media-shop text-left">
                                <span className="text-grey">with</span> Dive
                                Center Oahu
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="dive-details-top-right">
                          <div className="dive-details-top-right-box h-14 w-14 rounded-full flex justify-center items-center">
                            {diveDetails &&
                            diveDetails.scubaDive &&
                            diveDetails.scubaDive.isFreeDive ? (
                              <>
                                <img
                                  src="/images/Flipper.png"
                                  className="block md:hidden w-6 h-6"
                                />
                                <img
                                  src="/images/Flipper-Dark.png"
                                  className="hidden md:block w-6 h-6"
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src="/images/Air.png"
                                  className="block md:hidden w-6 h-6"
                                />
                                <img
                                  src="/images/Air-dark.png"
                                  className="hidden md:block w-6 h-6"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="pt-3 pb-3">
                        <div className="dive-details-tags-cont flex-row flex flex-wrap">
                          {diveDetails &&
                          diveDetails.scubaDive &&
                          diveDetails.scubaDive.tags &&
                          diveDetails.scubaDive.tags.length > 0
                            ? diveDetails.scubaDive.tags.map((item) => (
                                <div className="dive-details-tag-item h-8 pt-1 rounded-full mr-2 mb-2 pl-3 pr-3">
                                  {item.title}
                                </div>
                              ))
                            : null}
                        </div>
                      </div>

                      <div className="map-details-cont">
                        <div className="map-details-sub h-72 md:h-full">
                          {diveDetails &&
                          diveDetails.scubaDive &&
                          diveDetails.scubaDive.location &&
                          diveDetails.scubaDive.location.lat &&
                          diveDetails.scubaDive.location.lng ? (
                            <div className="pt-3 pb-3 h-full">
                              <div className="relative h-full">
                                <MyMapComponent
                                  currLocInfo={{
                                    lat: diveDetails.scubaDive.location.lat,
                                    lng: diveDetails.scubaDive.location.lng,
                                  }}
                                  className="rounded-xl h-full"
                                  // mapRef={mapRef}
                                  isMarkerShown
                                />
                                <div className="absolute p-3 flex flex-row w-full bottom-0 left-0">
                                  <div className="dive-details-location-abs-left dive-details-location-abs-cont backdrop-blur-sm w-full p-3 rounded-xl flex flex-row">
                                    <img
                                      className="h-8"
                                      src="/images/pinnormal.png"
                                    />
                                    <div className="flex flex-col flex-1 justify-center items-start pl-2">
                                      <div className="dive-details-location-title">
                                        {diveDetails.scubaDive.location.title}
                                      </div>
                                      <div className="dive-details-location-subtitle">
                                        {
                                          diveDetails.scubaDive.location
                                            .subTitle
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="map-details-sub ">
                          <div className="pt-3 pb-3 map-details-sub-1">
                            <div className="dive-green-cont rounded-xl p-3 pb-0 w-full">
                              <div className="flex flex-row pb-4">
                                <img className="h-5" src="/images/Time.png" />
                                <div className="dive-green-title pl-2">
                                  Time
                                </div>
                              </div>
                              <div className="grid grid-cols-2 text-left">
                                {diveDetails.scubaDive.timeIn ? (
                                  <div className="flex flex-col pb-4">
                                    <div className="dive-green-item-title pb-1">
                                      In
                                    </div>
                                    <div className="dive-green-item-subtitle">
                                      {moment(
                                        diveDetails.scubaDive.timeIn
                                      ).format("hh:mm A")}
                                    </div>
                                  </div>
                                ) : null}
                                {diveDetails.scubaDive.timeOut ? (
                                  <div className="flex flex-col pb-4">
                                    <div className="dive-green-item-title pb-1">
                                      Out
                                    </div>
                                    <div className="dive-green-item-subtitle">
                                      {moment(
                                        diveDetails.scubaDive.timeOut
                                      ).format("hh:mm A")}
                                    </div>
                                  </div>
                                ) : null}
                                {diveDetails.scubaDive.bottomTime ? (
                                  <div className="flex flex-col pb-4">
                                    <div className="dive-green-item-title pb-1">
                                      Bottom
                                    </div>
                                    <div className="dive-green-item-subtitle">
                                      {diveDetails.scubaDive.bottomTime} min
                                    </div>
                                  </div>
                                ) : null}
                                {diveDetails.scubaDive.totalTime ? (
                                  <div className="flex flex-col pb-4">
                                    <div className="dive-green-item-title pb-1">
                                      Total
                                    </div>
                                    <div className="dive-green-item-subtitle">
                                      {diveDetails.scubaDive.totalTime} min
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="pt-3 pb-3 map-details-sub-2">
                            <div className="dive-blue-cont rounded-xl p-3 pb-3 w-full">
                              <div className="flex flex-row pb-4">
                                <img
                                  className="h-5"
                                  src="/images/Depth-Icon.png"
                                />
                                <div className="dive-green-title pl-2">
                                  Depth
                                </div>
                              </div>
                              {diveDetails.scubaDive.isFreeDive ? (
                                <div className="free-dive-cont">
                                  <div className="grid grid-cols-10 pt-1 pb-1 border-b free-dive-header text-left">
                                    <div className="col-span-1 text-left pl-2">
                                      #
                                    </div>
                                    <div className="col-span-3">Depth</div>
                                    <div className="col-span-6">
                                      Time (min:sec)
                                    </div>
                                  </div>
                                  {diveDetails.scubaDive.freeDives.map(
                                    (item, index) => (
                                      <>
                                        <div className="grid grid-cols-10 pt-4 pb-2 text-left">
                                          <div className="col-span-1 free-dive-highlight text-left pl-2">
                                            {index + 1}
                                          </div>
                                          <div className="col-span-3 f-d-depth free-dive-highlight">
                                            {item.depth}m
                                          </div>
                                          <div className="col-span-4 f-d-time free-dive-highlight">
                                            {item.time}
                                          </div>
                                          {item.frc ? (
                                            <div className="col-span-2 flex justify-end">
                                              <div className="frc-tag text-center">
                                                FRC
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                        {item.depthAlarms &&
                                        Array.isArray(item.depthAlarms) &&
                                        item.depthAlarms.length > 0 ? (
                                          <div className="grid grid-cols-10 pt-1 pb-1 text-left">
                                            <div className="col-span-1 free-dive-highlight text-left pl-2 flex items-center">
                                              <img
                                                src="/images/Frame.png"
                                                className="w-3 h-auto opacity-50"
                                              />
                                            </div>
                                            <div className="col-span-9 f-d-depth free-dive-small flex flex-row">
                                              {item.depthAlarms &&
                                              Array.isArray(item.depthAlarms) &&
                                              item.depthAlarms.length > 0
                                                ? item.depthAlarms.map(
                                                    (
                                                      depthAlarmItem,
                                                      depthAlarmIndex
                                                    ) => (
                                                      <div className="depth-alarm-item flex flex-row mr-4">
                                                        <div className="depth-alarm-item-index pr-2 opacity-50">
                                                          {depthAlarmIndex + 1}
                                                        </div>
                                                        <div className="depth-alarm-item-content">
                                                          {depthAlarmItem}m
                                                        </div>
                                                      </div>
                                                    )
                                                  )
                                                : null}
                                            </div>
                                          </div>
                                        ) : null}
                                        <div className="grid grid-cols-10 mt-2 pt-1 pb-1 text-left surface-interval-cont">
                                          <div className="col-span-5 free-dive-s-i text-left pl-2 flex items-center opacity-50">
                                            Surface
                                          </div>
                                          <div className="col-span-5 free-dive-s-i text-left pl-2 flex items-center">
                                            {item.surfaceIntervalMin}:
                                            {item.surfaceIntervalSec}
                                          </div>
                                        </div>
                                      </>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div className="grid grid-cols-2 text-left">
                                  <div className="flex flex-col">
                                    <div className="dive-green-item-title pb-1">
                                      Max Depth
                                    </div>
                                    <div className="dive-green-item-subtitle">
                                      {diveDetails.scubaDive.maxDepth}{" "}
                                      {diveDetails.scubaDive.maxDepthUnit}
                                    </div>
                                  </div>
                                  {/* <div className="flex flex-col pb-4">
                        <div className="dive-green-item-title pb-1">In</div>
                        <div className="dive-green-item-subtitle">10:01 AM</div>
                      </div>
                      <div className="flex flex-col pb-4">
                        <div className="dive-green-item-title pb-1">In</div>
                        <div className="dive-green-item-subtitle">10:01 AM</div>
                      </div>
                      <div className="flex flex-col pb-4">
                        <div className="dive-green-item-title pb-1">In</div>
                        <div className="dive-green-item-subtitle">10:01 AM</div>
                      </div> */}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dive-details-top-outer-right w-full sm:w-1/2  md:w-1/3">
                      <div className="pt-3 pb-3">
                        <div className="dive-white-cont rounded-xl p-3 pb-3 w-full">
                          {!loading ? renderNotification() : null}
                          <div className="flex flex-row">
                            <img className="h-5" src="/images/Verified.png" />
                            <div className="dive-green-title pl-2">
                              Validate
                            </div>
                          </div>
                          <div className="flex flex-col text-left">
                            <div className="pt-4 pb-4 dive-white-desc">
                              Enter your professional details in order to
                              validate this Dive Log. Our team will do a
                              background check before the log gets validated.
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <div className="pb-4 flex-1 flex">
                              <TextField
                                id="standard-helperText"
                                label="Dive center"
                                defaultValue=""
                                className="w-full"
                                variant="outlined"
                                onChange={(e) => setDiveCenter(e.target.value)}
                                value={diveCenter}
                              />
                            </div>
                            <div className="pb-4 flex-1 flex">
                              <TextField
                                id="standard-helperText"
                                label="Instructor/Dive master name"
                                defaultValue=""
                                className="w-full"
                                variant="outlined"
                                onChange={(e) => setDiveMaster(e.target.value)}
                                value={diveMaster}
                              />
                            </div>
                            <div className="pb-4 flex-1 flex">
                              <TextField
                                id="standard-helperText"
                                label="Professional PADI / SSI ID"
                                defaultValue=""
                                className="w-full"
                                variant="outlined"
                                onChange={(e) =>
                                  setDiveProfessionalId(e.target.value)
                                }
                                value={diveProfessionalId}
                              />
                            </div>
                            <div className="mb-3 flex-1 flex">
                              <CustomButton
                                className="w-full"
                                onClick={approveDive}
                                variant="contained"
                              >
                                {isApproveLoading ? (
                                  <ReactLoading
                                    type={"bubbles"}
                                    color={"#fff"}
                                    width={30}
                                    height={30}
                                  />
                                ) : (
                                  <div>Submit validation</div>
                                )}
                              </CustomButton>
                            </div>
                            <CustomButton
                              className=""
                              onClick={rejectDive}
                              variant="contained"
                            >
                              <div>Reject dive</div>
                            </CustomButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* <div className="hidden md:block mx-3 mb-4 relative items-center justify-center  rounded-xl">
              <img src="/images/footer-image.png" />
            </div> */}
            </div>
          </Default>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;

const MyMapComponent = compose(
  withStateHandlers(
    () => ({
      // isMarkerShown: false,
      markerPosition: null,
    }),
    {
      onMapClick:
        ({}) =>
        (e) => ({
          markerPosition: e.latLng,
          // isMarkerShown: true
        }),
    }
  ),
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAgG4PP0uGkL7sW17Upnz3HAKuF0NCiOf4&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div
        style={{
          height: `100%`,
          width: "100%",
          borderRadius: 18,
          overflow: "hidden",
        }}
      />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  let {
    currLocInfo,
    isMarkerShown,
    mapRef,
    setUpdateLocation,
    onMapClick,
    markerPosition,
    updateLocation,
    className,
  } = props;
  useEffect(() => {
    // console.log(markerPosition && markerPosition.lat());
    if (markerPosition) {
      setUpdateLocation({
        ...updateLocation,
        lat: markerPosition.lat(),
        lng: markerPosition.lng(),
      });
    }
  }, [markerPosition]);
  return (
    <GoogleMap
      options={{ disableDefaultUI: true }}
      ref={mapRef}
      className={className}
      mapTypeId="satellite"
      defaultZoom={8}
      disableDefaultUi={true}
      defaultCenter={{
        lat: parseFloat(currLocInfo.lat),
        lng: parseFloat(currLocInfo.lng),
      }}
      // onClick={(ev) => {
      //   // console.log(mapRef.getBounds());
      //   // console.log('latitide = ', ev.latLng);
      //   // console.log('longitude = ', ev.latLng.lng());
      //   }}
      onClick={onMapClick}
    >
      {isMarkerShown && currLocInfo && (
        <>
          <Marker
            icon={{
              url: "/images/pinnormal.png",
              scaledSize: { width: 35, height: 40 },
            }}
            position={{
              lat: parseFloat(currLocInfo.lat),
              lng: parseFloat(currLocInfo.lng),
            }}
          />
          {/* {markerPosition && (
            <Marker
              icon={{
                url: '/images/pinnormal.png',
                scaledSize: { width: 35, height: 40 },
              }}
              position={markerPosition}
            />
          )} */}
        </>
      )}
    </GoogleMap>
  );
});
