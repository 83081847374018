import React from "react";
import {IOS_APP_URL} from "../constants";

const Header = ({logoSrc}) => {
  const goToSignUp = () => {
    window.location.href = IOS_APP_URL;
  };
  return (
    <div className="flex flex-row justify-between items-center pl-3 pr-3 py-3">
      <div className="dive-header-logo-cont">
        <img className="w-32" src={logoSrc} alt="logo" />
      </div>
      <button
        onClick={() => goToSignUp()}
        className="bg-orange text-white rounded-3xl px-5 py-2 text-base"
      >
        Sign up
      </button>
    </div>
  );
};

export default Header;
