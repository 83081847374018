/* eslint-disable no-undef */
import React, {useEffect, useState} from "react";
import {createTheme, ThemeProvider, styled} from "@mui/material/styles";
import {makeStyles} from "@mui/styles";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import ApiService from "../api/ApiService";
import config from "../config";
import ReactLoading from "react-loading";

import Default from "../layout/Default";

const headerBlackLogo = "/images/logos/logo-black-new.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(249, 127, 102)",
    },
    secondary: {
      main: "rgb(249, 127, 102)",
    },
  },
});

function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function BlogPost(props) {
  const [loading, setLoading] = useState(false);
  const [isSuccessfull, setIsSuccessfull] = useState(false);

  const [blogDetails, setBlogDetails] = useState(null);
  const [afterData, setAfterData] = useState(false);

  const [isUnauthorized, setIsUnauthorized] = useState(null);
  const [qrCodeData, setQrCodeData] = useState(null);

  let query = useQuery();
  let params = useParams();
  useEffect(() => {
    checkIfVerified();
    document.getElementById("user-main-cont-id").classList.add("bg-white");

    document.querySelector("body").classList.add("bg-white");
  }, []);
  const checkIfVerified = async () => {
    console.log("params", params);
    if (params && params.id) {
      setLoading(true);
      try {
        const responseOfDive = await ApiService.getBlogDetailsFromId(params.id);
        setIsUnauthorized(false);
        setLoading(false);
        if (responseOfDive && responseOfDive.data) {
          // setDiveDetails(responseOfDive.data);
          setBlogDetails(responseOfDive.data);
          generateBranchQRCode(responseOfDive.data);
          setIsSuccessfull(true);
        } else {
          setIsSuccessfull(false);
        }
      } catch (error) {
        setLoading(false);
        setIsUnauthorized(true);
        setIsSuccessfull(false);
      }
    } else {
      alert("Unauthorized Request");
      setLoading(false);
      setIsUnauthorized(true);
      setIsSuccessfull(false);
    }
  };

  const renderNotification = () => (
    <>
      {/* <div className="flex flex-1 py-6 px-2 flex-row notify items-center">
        <div className="flex flex-1 notify-text">
          Quin Favre is requesting you to validate this dive log
        </div>
        <div className="flex flex-row">
          <button className="btn-lite btn-lite-txt">Decline</button>

          <button className="btn-orange btn-orange-txt">Validate</button>
        </div>
      </div> */}
    </>
  );
  function extractDomain(url) {
    let domain;
    // Find & remove protocol (http, ftp, etc.) and get domain
    if (url.indexOf("://") > -1) {
      domain = url.split("/")[2];
    } else {
      domain = url.split("/")[0];
    }
    // Find & remove www
    if (domain.indexOf("www.") > -1) {
      domain = domain.split("www.")[1];
    }
    // Find & remove port number
    domain = domain.split(":")[0];
    return domain;
  }

  const generateBranchQRCode = async (blogDetailsForShare) => {
    if (blogDetailsForShare && blogDetailsForShare.id) {
      var linkData = {
        campaign: "web share",
        feature: "blog share",
        stage: "generate deeplink",
        data: {
          $og_title: "Blog",
          $og_description: `Check out the blog on Buddy app`,
          $og_image_url: blogDetailsForShare?.image,
          $desktop_url: `${config.REACT_APP_SHARE_URL}blog/${blogDetailsForShare?.id}`,
          $canonical_identifier: `blog/${blogDetailsForShare?.id}`,
        },
      };
      branch.link(linkData, function (err, link) {
        console.log("link", link);
        setQrCodeData({
          type: "BLOG",
          // title: `${blogDetailsForShare?.firstName}`,
          shareLink: link,
        });
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div id="user-main-cont-id" className="dive-main-cont flex-1 h-full">
        <div className="main-site-wrapper-wide h-full flex-col flex">
          <Default
            qrCodeData={qrCodeData}
            logoSrc={headerBlackLogo}
            footerPaddingBottom={
              blogDetails?.externalLinkButtonLink ? "pb-[80px]" : ""
            }
          >
            {renderNotification()}
            {loading ? (
              <div className="flex justify-center items-center h-96">
                <ReactLoading
                  type={"bubbles"}
                  color={"#fff"}
                  width={50}
                  height={50}
                />
              </div>
            ) : isSuccessfull && afterData ? (
              <div className="dive-details-cont flex flex-col pl-3 pr-3 justify-center items-center ">
                <div className="h-20 w-20 mt-7 mb-7">
                  <svg
                    fill="#0DCE89"
                    version="1.1"
                    id="lni_lni-checkmark-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 64 64"
                  >
                    <g>
                      <g>
                        <path
                          d="M32,62.8C15,62.8,1.3,49,1.3,32C1.3,15,15,1.3,32,1.3C49,1.3,62.8,15,62.8,32C62.8,49,49,62.8,32,62.8z M32,4.8
              C17,4.8,4.8,17,4.8,32C4.8,47,17,59.3,32,59.3C47,59.3,59.3,47,59.3,32C59.3,17,47,4.8,32,4.8z"
                        />
                      </g>
                      <g>
                        <path
                          d="M28.6,39c-0.7,0-1.3-0.2-1.9-0.7l-6.6-6.4c-0.7-0.7-0.7-1.8,0-2.5c0.7-0.7,1.8-0.7,2.5,0l6,5.9l12.8-12.4
              c0.7-0.7,1.8-0.7,2.5,0c0.7,0.7,0.7,1.8,0,2.5l-13.3,13C29.9,38.8,29.2,39,28.6,39z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="dive-details-title pb-10 border-b border-white text-center">
                  Your request is sent to Buddy Admin for validation! Thanks for
                  validating, you can now close the window
                </div>
              </div>
            ) : blogDetails && blogDetails ? (
              <>
                <div className="flex flex-1">
                  <div className="blogs flex flex-1 flex-col relative">
                    {blogDetails.image && (
                      <div className="header-image">
                        <img
                          src={blogDetails.image}
                          className="border-0 rounded-none md:rounded-xl w-[686px] h-[375px] mx-auto "
                          alt="header pic"
                        />
                      </div>
                    )}
                    <div className="blog-content w-full h-full md:w-[686px] mx-auto px-6 md:px-[115px] text-left ">
                      {blogDetails.blogCategory && (
                        <div className="text-[15px] font-basisGrotesqueMedium text-black py-6">
                          {blogDetails.blogCategory}
                        </div>
                      )}
                      {blogDetails.htmlContent && (
                        <div
                          className="html-content"
                          dangerouslySetInnerHTML={{
                            __html: blogDetails.htmlContent,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {blogDetails.externalLinkButtonLink && (
                  <div className="flex justify-center items-center  fixed bottom-0 w-full flex-col bg-gradient-to-t  from-white via-white to-transparent opacity-90 ">
                    <a
                      href={blogDetails.externalLinkButtonLink}
                      className="bg-blue-500 text-white  py-4  my-6 inline-block bg-black px-16 rounded-full  "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit {extractDomain(blogDetails.externalLinkButtonLink)}
                    </a>
                  </div>
                )}
              </>
            ) : null}
          </Default>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default BlogPost;
