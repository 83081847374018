import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({
  children,
  logoSrc,
  showQRCode,
  qrCodeData,
  footerPaddingBottom,
}) => (
  <>
    <Header logoSrc={logoSrc} />
    {children}
    <Footer
      qrCodeData={qrCodeData}
      showQRCode={showQRCode}
      footerPaddingBottom={footerPaddingBottom}
    />
  </>
);

export default Layout;
