/* eslint-disable no-undef */
import "./App.scss";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyEmail from "./pages/VerifyEmail";
import ValidateDive from "./pages/ValidateDive";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Dive from "./pages/Dive";
import User from "./pages/User";
import DiveShop from "./pages/DiveShop";
import { Routes, Route } from "react-router-dom";
import DownloadApp from "./pages/DownloadApp";
import Gear from "./pages/Gear";
import BlogPost from "./pages/BlogPost";
import { useEffect } from "react";
import config from "./config";

function App() {
  useEffect(() => {
    console.log("Initialize Branch");
    // Initialize the Branch SDK
    console.log("branch", branch);
    branch.init(config.REACT_APP_BRANCH_KEY, (err, data) => {
      console.log("Branch initialized", err, data);
    });
  }, []);
  return (
    <div className="App h-full">
      <Routes>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/validate-dive" element={<ValidateDive />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/dive/:id" element={<Dive />} />
        <Route path="/user/:id" element={<User />} />
        <Route path="/diveshop/:id" element={<DiveShop />} />
        <Route path="/download-app" element={<DownloadApp />} />
        <Route path="/gear/:id/:userId" element={<Gear />} />
        <Route path="/blog/:id" element={<BlogPost />} />
      </Routes>
    </div>
  );
}

export default App;
