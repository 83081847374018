import React, {useState} from "react";
import CookieConsent from "react-cookie-consent";
import QrCard from "../components/QrCard";
import config from "../config";

const Footer = ({
  showQRCode = true,
  qrCodeData = null,
  footerPaddingBottom = "",
}) => {
  const [qrCardVisible, setQrCardVisible] = useState(true);

  const toggleQrCardVisibility = () => {
    setQrCardVisible(!qrCardVisible);
  };

  return (
    <>
      <div className={`text-center ${footerPaddingBottom}`}>
        <a
          href={`${config.REACT_APP_BUDDY_WEBSITE_URL}/aloha`}
          className="text-weak text-xs py-4 px-3 inline-block"
        >
          About Buddy
        </a>
        <a
          href={`${config.REACT_APP_BUDDY_WEBSITE_URL}/privacy`}
          className="text-weak text-xs py-4 px-3 inline-block"
        >
          Privacy Policy
        </a>
        <a
          href={`${config.REACT_APP_BUDDY_WEBSITE_URL}/terms-conditions`}
          className="text-weak text-xs py-4 px-3 inline-block"
        >
          Terms & Conditions
        </a>

        {/* QrCard custom components */}
        {showQRCode && qrCardVisible && qrCodeData && (
          <div className="fixed !bottom-3 !right-0 sm:!right-3  !text-white  !bg-azure !rounded-2xl !text-base !text-left !m-3 sm:!m-0 sm:!w-auto ">
            <div className="bg-gray-200 rounded-lg">
              <QrCard
                qrCodeData={qrCodeData}
                onClose={toggleQrCardVisibility}
              />
            </div>
          </div>
        )}
        <CookieConsent
          location="bottom"
          buttonText="Accept all"
          enableDeclineButton
          declineButtonText="Reject all"
          declineButtonClasses="!m-0 !color-white !rounded-3xl !text-sm !mr-2 !bg-darkBlue !py-2 !px-4"
          declineButtonStyle={{
            borderWidth: 2,
            borderColor: "#333A48",
            borderStyle: "solid",
          }}
          containerClasses="absolute !bottom-3 !left-0 sm:!left-3 !max-w-[400px] !w-auto !text-white !p-5 !bg-darkBlue !rounded-2xl !text-base !text-left !m-3 sm:!m-0"
          buttonClasses="!m-0 !bg-white !color-darkBlue !rounded-3xl !text-sm !py-2 !px-4"
          cookieName="buddyAppCookie"
          contentClasses="!text-white !m-0 !mb-4 !flex-initial"
          expires={150}
        >
          Buddy uses cookies to personalize content and ads, provide social
          media features, and analyze our traffic. Click "Accept all" to accept
          these uses. Read more in our Cookie Policy.
        </CookieConsent>
      </div>
    </>
  );
};

export default Footer;
