import React from "react";
import QRCode from "react-qr-code";

const QrCard = ({ onClose, qrCodeData }) => {
  return (
    <div className="qrCard text-center bg-cover bg-center bg-no-repeat relative   w-full sm:w-[400px] mx-auto ">
      <div className="absolute inset-0">
        <img
          src="/images/Illustration.png"
          alt="background"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="qrCard-content relative z-10 px-6 py-6 ">
        <button
          className="absolute top-0 right-0 m-3 text-white bg-darkBlue rounded-full w-8 h-8 flex items-center justify-center"
          //onClick={() => console.log("Close button clicked")}
          onClick={onClose}
        >
          <img src="/images/close.png" alt="close" className="" />
        </button>
        {qrCodeData && qrCodeData.type === "USER" && (
          <div className="qrCard-title font-athleticsBold text-2xl text-darkAzure leading-6">
            View {qrCodeData.title}’s full profile in the Buddy app
          </div>
        )}
        {qrCodeData && qrCodeData.type === "DIVE_SHOP" && (
          <div className="qrCard-title font-athleticsBold text-2xl text-darkAzure leading-6">
            View {qrCodeData.title}’s full profile in the Buddy app
          </div>
        )}
        {qrCodeData && qrCodeData.type === "DIVE" && (
          <div className="qrCard-title font-athleticsBold text-2xl text-darkAzure leading-6">
            View {qrCodeData.title}’s full dive log in the Buddy app
          </div>
        )}
        {qrCodeData && qrCodeData.type === "GEAR" && (
          <div className="qrCard-title font-athleticsBold text-2xl text-darkAzure leading-6">
            Find more gear reviews in the Buddy app
          </div>
        )}
        {qrCodeData && qrCodeData.type === "BLOG" && (
          <div className="qrCard-title font-athleticsBold text-2xl text-darkAzure leading-6">
            To read the full article download the Buddy app
          </div>
        )}
        <div className="qrCard-subtitle text-darkAzure text-[17px] pt-6 pb-6 leading-6">
          Scan the code below with your <br /> phone to download
        </div>
        <div className="qrCard-img justify-center items-center flex pb-2">
          <QRCode
            className="bg-white p-4 rounded-xl"
            value={qrCodeData?.shareLink}
            size={200}
          />
        </div>
      </div>
    </div>
  );
};

export default QrCard;
