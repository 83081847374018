const development = {
  REACT_APP_API_URL: "https://api-staging.divewithbuddy.com",
  REACT_APP_BRANCH_KEY: "key_test_isfyQvgqXuwQFXebYRlCKcgdzCgnBNBT",
  REACT_APP_SHARE_URL: "https://sharestaging.divewithbuddy.com/",
  REACT_APP_BRANCH_DOWNLOAD_APP_URL:
    "https://divewithbuddy.test-app.link/download",
  REACT_APP_BUDDY_WEBSITE_URL: "https://divewithbuddy.com",
};
const staging = {
  REACT_APP_API_URL: "https://api-staging.divewithbuddy.com",
  REACT_APP_BRANCH_KEY: "key_test_isfyQvgqXuwQFXebYRlCKcgdzCgnBNBT",
  REACT_APP_SHARE_URL: "https://sharestaging.divewithbuddy.com/",
  REACT_APP_BRANCH_DOWNLOAD_APP_URL:
    "https://divewithbuddy.test-app.link/download",
  REACT_APP_BUDDY_WEBSITE_URL: "https://divewithbuddy.com",
};
const production = {
  REACT_APP_API_URL: "https://api-prod.divewithbuddy.com",
  REACT_APP_BRANCH_KEY: "key_live_gwcsRyly3tFGD4ae6SeqMpjfuCkbtPBE",
  REACT_APP_SHARE_URL: "https://share.divewithbuddy.com/",
  REACT_APP_BRANCH_DOWNLOAD_APP_URL: "https://divewithbuddy.app.link/download",
  REACT_APP_BUDDY_WEBSITE_URL: "https://divewithbuddy.com",
};
const config =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? production
    : process.env.REACT_APP_ENVIRONMENT === "staging"
    ? staging
    : development;
export default {
  ...config,
};
