/* eslint-disable no-undef */
import React, {useEffect, useState} from "react";
import {createTheme, ThemeProvider, styled} from "@mui/material/styles";
import {makeStyles} from "@mui/styles";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import ApiService from "../api/ApiService";
import config from "../config";
import ReactLoading from "react-loading";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import Button from "@mui/material/Button";
import {compose, withProps, withStateHandlers} from "recompose";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Default from "../layout/Default";

const headerWhiteLogo = "/images/logos/logo.png";
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(249, 127, 102)",
    },
    secondary: {
      main: "rgb(249, 127, 102)",
    },
  },
});
const CustomButton = styled(Button)(({theme}) => ({
  color: "#fff",
  borderRadius: 30,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 10,
  paddingBottom: 10,
  textTransform: "none",
}));
const CustomButtonApprove = styled(Button)(({theme}) => ({
  color: "#fff",
  borderRadius: 30,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 10,
  paddingBottom: 10,
  backgroundColor: "#4CAF50",
}));

function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App(props) {
  const [loading, setLoading] = useState(false);
  const [isSuccessfull, setIsSuccessfull] = useState(false);
  const [diveDetails, setDiveDetails] = useState(null);
  console.log("diveDetails=>", diveDetails);
  const [afterData, setAfterData] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [diveCenter, setDiveCenter] = useState("");
  const [diveMaster, setDiveMaster] = useState("");
  const [diveProfessionalId, setDiveProfessionalId] = useState("");
  const [isUnauthorized, setIsUnauthorized] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [qrCodeData, setQrCodeData] = useState(null);
  let query = useQuery();
  let params = useParams();
  useEffect(() => {
    checkIfVerified();
    document.getElementById("dive-main-cont-id").classList.add("bg-white");
    document.querySelector("body").classList.add("bg-white");
  }, []);
  const checkIfVerified = async () => {
    console.log("params", params);
    if (params && params.id) {
      setLoading(true);
      try {
        const responseOfDive = await ApiService.getDiveDetailsFromId({
          diveId: params.id,
        });
        setIsUnauthorized(false);
        setLoading(false);
        if (responseOfDive && responseOfDive.data) {
          setDiveDetails(responseOfDive.data);
          generateBranchQRCode(responseOfDive.data);
          setIsSuccessfull(true);
        } else {
          setIsSuccessfull(false);
        }
      } catch (error) {
        setLoading(false);
        setIsUnauthorized(true);
        setIsSuccessfull(false);
      }
    } else {
      alert("Unauthorized Request");
      setLoading(false);
      setIsUnauthorized(true);
      setIsSuccessfull(false);
    }
  };
  const calculateBottomTime = () => {
    if (diveDetails && diveDetails.timeIn && diveDetails.timeOut) {
      const timeIn = moment(diveDetails.timeIn);
      const timeOut = moment(diveDetails.timeOut);
      const duration = moment.duration(timeOut.diff(timeIn));
      const hours = Math.floor(duration.asHours());
      const minutes = Math.round(duration.asMinutes() % 60);
      return {hours, minutes};
    }
    return null;
  };
  const generateBranchQRCode = async (diveDetailsForShare) => {
    if (diveDetailsForShare && diveDetailsForShare.id) {
      var linkData = {
        campaign: "web share",
        feature: "dive share",
        stage: "generate deeplink",
        data: {
          $og_title: "Scuba Dive",
          $og_description: `Check this dive added by ${diveDetailsForShare?.createdByUserId?.firstName} ${diveDetailsForShare?.createdByUserId?.lastName}`,
          $og_image_url: diveDetailsForShare?.createdByUserId?.profilePic,
          desktop_url: `${config.REACT_APP_SHARE_URL}dive/${diveDetailsForShare?.id}`,
          $canonical_identifier: `dive/${diveDetailsForShare?.id}`,
        },
      };
      branch.link(linkData, function (err, link) {
        setQrCodeData({
          type: "DIVE",
          title: diveDetailsForShare?.createdByUserId?.firstName,
          shareLink: link,
        });
      });
    }
  };
  const renderNotification = () => (
    <>
      {/* <div className="flex flex-1 py-6 px-2 flex-row notify items-center">
        <div className="flex flex-1 notify-text">
          Quin Favre is requesting you to validate this dive log
        </div>
        <div className="flex flex-row">
          <button className="btn-lite btn-lite-txt">Decline</button>

          <button className="btn-orange btn-orange-txt">Validate</button>
        </div>
      </div> */}
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <div id="dive-main-cont-id" className="dive-main-cont flex-1 h-full ">
        <div className="main-site-wrapper-wide bg-black !h-auto md:!min-h-full flex-col flex ">
          <Default qrCodeData={qrCodeData} logoSrc={headerWhiteLogo}>
            {renderNotification()}
            {loading ? (
              <div className="flex justify-center items-center h-96">
                <ReactLoading
                  type={"bubbles"}
                  color={"#fff"}
                  width={50}
                  height={50}
                />
              </div>
            ) : isSuccessfull && afterData ? (
              <div className="dive-details-cont flex flex-col pl-3 pr-3 justify-center items-center ">
                <div className="h-20 w-20 mt-7 mb-7">
                  <svg
                    fill="#0DCE89"
                    version="1.1"
                    id="lni_lni-checkmark-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 64 64"
                  >
                    <g>
                      <g>
                        <path
                          d="M32,62.8C15,62.8,1.3,49,1.3,32C1.3,15,15,1.3,32,1.3C49,1.3,62.8,15,62.8,32C62.8,49,49,62.8,32,62.8z M32,4.8
			C17,4.8,4.8,17,4.8,32C4.8,47,17,59.3,32,59.3C47,59.3,59.3,47,59.3,32C59.3,17,47,4.8,32,4.8z"
                        />
                      </g>
                      <g>
                        <path
                          d="M28.6,39c-0.7,0-1.3-0.2-1.9-0.7l-6.6-6.4c-0.7-0.7-0.7-1.8,0-2.5c0.7-0.7,1.8-0.7,2.5,0l6,5.9l12.8-12.4
			c0.7-0.7,1.8-0.7,2.5,0c0.7,0.7,0.7,1.8,0,2.5l-13.3,13C29.9,38.8,29.2,39,28.6,39z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="dive-details-title pb-10 border-b border-white text-center">
                  Your request is sent to Buddy Admin for validation! Thanks for
                  validating, you can now close the window
                </div>
              </div>
            ) : diveDetails && diveDetails ? (
              <div className="dive-details-cont flex flex-col pl-3 pr-3 flex-1">
                <div className="flex  flex-col justify-center items-center flex-1 ">
                  <div className="user-details-data m-10">
                    <div className="user-detail-profile-pic">
                      {console.log("diveDetails==>", diveDetails)}
                      {diveDetails &&
                      diveDetails?.createdByUserId?.profilePic &&
                      diveDetails?.createdByUserId?.profilePic !== "" ? (
                        <img
                          src={diveDetails?.createdByUserId?.profilePic}
                          className=" dive-details-top-img rounded-full  h-20 w-20 m-auto"
                          alt="profile pic"
                        />
                      ) : (
                        <img
                          src="/images/scubaDiverDefault.png"
                          className="mt-1 dive-details-top-img rounded-full h-20 w-20 m-auto"
                          alt="profile pic"
                        />
                      )}
                    </div>
                    <div className="user-detail-profile-name user-detail-txt font-bold  text-2xl">
                      {diveDetails ? (
                        <>
                          <div className="dive-details-top-title-cont py-6 ">
                            <div className="user-details-top-title text-white font-athleticsBold text-4xl">
                              {diveDetails?.createdByUserId?.firstName}{" "}
                              {diveDetails?.createdByUserId?.lastName}
                            </div>
                            <div className="user-details-diverLevel text-white font-basisGrotesqueMedium font-medium text-[17px] leading-6">
                              {diveDetails?.createdByUserId?.diverLevel}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="loc-diveDetails flex  flex-col gap-6 md:flex-row w-full justify-center  md:max-w-[1000px] max-w-[480px] ">
                    <div className="map-details-sub md:h-full !h-[400px] w-full md:w-[456px] sm:w-full">
                      {diveDetails &&
                      diveDetails?.location?.lat &&
                      diveDetails?.location?.lng ? (
                        <div className="h-full">
                          <div className="relative h-full">
                            <MyMapComponent
                              currLocInfo={{
                                lat: parseFloat(diveDetails?.location?.lat),
                                lng: parseFloat(diveDetails?.location?.lng),
                              }}
                              className="rounded-xl h-full"
                              // mapRef={mapRef}
                              isMarkerShown
                            />
                            <div className="absolute p-3 flex flex-row w-full bottom-0 left-0">
                              <div className="dive-details-location-abs-left dive-details-location-abs-cont backdrop-blur-sm w-full p-3 rounded-xl flex flex-row">
                                <img
                                  className="h-8"
                                  src="/images/pinnormal.png"
                                  alt="pin"
                                />
                                <div className="flex flex-col flex-1 justify-center items-start pl-2">
                                  <div className="dive-details-location-title">
                                    {diveDetails?.location?.title}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* <div className="dive-details md:h-full !h-[400px] w-full md:w-[468px] flex flex-col gap-6"> */}
                    <div className="dive-details md:h-full  w-full md:w-[456px] flex flex-col gap-6 sm:w-full justify-between">
                      <div className="dive-date flex ">
                        {diveDetails && diveDetails.isFreeDive ? (
                          <img
                            className=" bg-grayDark  border-0 rounded-full"
                            src="/images/Freedive.png"
                            alt="free-dive-img"
                          />
                        ) : (
                          <img
                            className=" bg-grayDark  border-0 rounded-full"
                            src="/images/Scubadive.png"
                            alt="scuba-dive-img"
                          />
                        )}
                        <div className="flex items-center pl-3 font-basisGrotesqueMedium text-[17px] leading-6">
                          <div className="text-white">
                            {diveDetails && diveDetails.isFreeDive ? (
                              <div className="dive">
                                <span className="freedive">Freedive</span>
                              </div>
                            ) : (
                              <div className="dive">
                                <span className="scubadive">Scuba dive</span>
                              </div>
                            )}
                          </div>

                          <div className="text-weak  ">&nbsp;on&nbsp;</div>
                          <div className="text-white">
                            {moment(diveDetails?.date).format("MMMM DD, yyyy")}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-6">
                        <div className=" map-details-sub-1">
                          <div className="dive-green-cont rounded-xl p-3 pb-0 w-full">
                            <div className="flex flex-row pb-4">
                              <img className="h-5" src="/images/Time.png" />
                              <div className="dive-green-title pl-2">Time</div>
                            </div>
                            <div className="grid grid-cols-2 text-left">
                              {diveDetails.timeIn ? (
                                <div className="flex flex-col pb-4">
                                  <div className="dive-green-item-title pb-1 !font-basisGrotesqueRegular text-sm">
                                    In
                                  </div>
                                  <div className="dive-green-item-subtitle">
                                    {moment(diveDetails?.timeIn).format(
                                      "hh:mm A"
                                    )}
                                  </div>
                                </div>
                              ) : null}
                              {diveDetails.timeOut ? (
                                <div className="flex flex-col pb-4">
                                  <div className="dive-green-item-title pb-1 font-basisGrotesqueRegular text-sm">
                                    Out
                                  </div>
                                  <div className="dive-green-item-subtitle">
                                    {moment(diveDetails?.timeOut).format(
                                      "hh:mm A"
                                    )}
                                  </div>
                                </div>
                              ) : null}
                              {diveDetails.bottomTime ? (
                                <div className="flex flex-col pb-4">
                                  <div className="dive-green-item-title pb-1">
                                    Bottom
                                  </div>
                                  <div className="dive-green-item-subtitle">
                                    {calculateBottomTime().hours} hr{" "}
                                    {calculateBottomTime().minutes} min
                                  </div>
                                </div>
                              ) : null}
                              {diveDetails.totalTime ? (
                                <div className="flex flex-col pb-4">
                                  <div className="dive-green-item-title pb-1">
                                    Total
                                  </div>
                                  <div className="dive-green-item-subtitle">
                                    {diveDetails?.totalTime} min
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="map-details-sub-2">
                          <div className="dive-blue-cont rounded-xl p-3 pb-3 w-full">
                            <div className="flex flex-row pb-4">
                              <img
                                className="h-5"
                                src="/images/Depth-Icon.png"
                              />
                              <div className="dive-green-title pl-2">Depth</div>
                            </div>
                            {diveDetails.isFreeDive ? (
                              <div className="free-dive-cont">
                                <div className="grid grid-cols-10 pt-1 pb-1 border-b free-dive-header text-left">
                                  <div className="col-span-1 text-left pl-2">
                                    #
                                  </div>
                                  <div className="col-span-3">Depth</div>
                                  <div className="col-span-6">
                                    Time (min:sec)
                                  </div>
                                </div>
                                {diveDetails?.freeDives.map((item, index) => (
                                  <>
                                    <div className="grid grid-cols-10 pt-4 pb-2 text-left">
                                      <div className="col-span-1 free-dive-highlight text-left pl-2">
                                        {index + 1}
                                      </div>
                                      <div className="col-span-3 f-d-depth free-dive-highlight">
                                        {item?.depth}m
                                      </div>
                                      <div className="col-span-4 f-d-time free-dive-highlight">
                                        {item?.time}
                                      </div>
                                      {item.frc ? (
                                        <div className="col-span-2 flex justify-end">
                                          <div className="frc-tag text-center">
                                            FRC
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>

                                    {item?.depthAlarms &&
                                    Array.isArray(item.depthAlarms) ? (
                                      <div className="grid grid-cols-10 pt-1 pb-1 text-left">
                                        <div className="col-span-1 free-dive-highlight text-left pl-2 flex items-center">
                                          <img
                                            src="/images/Frame.png"
                                            className="w-3 h-auto opacity-50"
                                          />
                                        </div>
                                        <div className="col-span-9 f-d-depth free-dive-small flex flex-row justify-between">
                                          {[...Array(5)].map(
                                            (_, depthAlarmIndex) => {
                                              const depthAlarmItem =
                                                item.depthAlarms[
                                                  depthAlarmIndex
                                                ];
                                              return (
                                                <div
                                                  className="depth-alarm-item flex flex-row mr-4"
                                                  key={depthAlarmIndex}
                                                >
                                                  <div className="depth-alarm-item-index pr-2 opacity-50">
                                                    {depthAlarmIndex + 1}
                                                  </div>
                                                  <div className="depth-alarm-item-content">
                                                    {depthAlarmItem
                                                      ? `${depthAlarmItem}m`
                                                      : "-"}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="grid grid-cols-10 pt-1 pb-1 text-left">
                                        <div className="col-span-1 free-dive-highlight text-left pl-2 flex items-center">
                                          <img
                                            src="/images/Frame.png"
                                            className="w-3 h-auto opacity-50"
                                            alt="bell-icon"
                                          />
                                        </div>
                                        <div className="col-span-9 f-d-depth free-dive-small flex flex-row justify-between">
                                          {[...Array(5)].map(
                                            (_, depthAlarmIndex) => (
                                              <div
                                                className="depth-alarm-item flex flex-row mr-4"
                                                key={depthAlarmIndex}
                                              >
                                                <div className="depth-alarm-item-index pr-2 opacity-50">
                                                  {depthAlarmIndex + 1}
                                                </div>
                                                <div className="depth-alarm-item-content">
                                                  -
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    <div className="grid grid-cols-10 mt-2 pt-1 pb-1 text-left surface-interval-cont">
                                      <div className="col-span-5 free-dive-s-i text-left pl-2 flex items-center opacity-50">
                                        Surface
                                      </div>
                                      <div className="col-span-5 free-dive-s-i text-left pl-2 flex items-center">
                                        {item?.surfaceIntervalMin}:
                                        {item?.surfaceIntervalSec}
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </div>
                            ) : (
                              <div className="grid grid-cols-2 text-left">
                                <div className="flex flex-col">
                                  <div className="dive-green-item-title pb-1">
                                    Max depth
                                  </div>
                                  <div className="dive-green-item-subtitle">
                                    {diveDetails?.maxDepth}{" "}
                                    {diveDetails?.maxDepthUnit}
                                  </div>
                                </div>
                                <div className="flex flex-col">
                                  <div className="dive-green-item-title pb-1">
                                    Bottom
                                  </div>
                                  <div className="dive-green-item-subtitle">
                                    {calculateBottomTime().hours} hr{" "}
                                    {calculateBottomTime().minutes} min
                                    {/* {calculateBottomTime()} min */}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </Default>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;

const MyMapComponent = compose(
  withStateHandlers(
    () => ({
      // isMarkerShown: false,
      markerPosition: null,
    }),
    {
      onMapClick:
        ({}) =>
        (e) => ({
          // markerPosition: e.latLng,
          // isMarkerShown: true
        }),
    }
  ),
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAgG4PP0uGkL7sW17Upnz3HAKuF0NCiOf4&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{height: `100%`}} />,
    containerElement: (
      <div
        style={{
          height: `100%`,
          width: "100%",
          borderRadius: 18,
          overflow: "hidden",
        }}
        className="map-view"
      />
    ),
    mapElement: <div style={{height: `100%`}} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  let {
    currLocInfo,
    isMarkerShown,
    mapRef,
    setUpdateLocation,
    onMapClick,
    markerPosition,
    updateLocation,
    className,
  } = props;
  useEffect(() => {
    // console.log(markerPosition && markerPosition.lat());
    if (markerPosition) {
      setUpdateLocation({
        ...updateLocation,
        lat: markerPosition.lat(),
        lng: markerPosition.lng(),
      });
    }
  }, [markerPosition]);
  return (
    <GoogleMap
      options={{disableDefaultUI: true}}
      ref={mapRef}
      className={className}
      mapTypeId="satellite"
      defaultZoom={8}
      disableDefaultUi={true}
      defaultCenter={{
        lat: parseFloat(currLocInfo.lat),
        lng: parseFloat(currLocInfo.lng),
      }}
      // onClick={(ev) => {
      //   // console.log(mapRef.getBounds());
      //   // console.log('latitide = ', ev.latLng);
      //   // console.log('longitude = ', ev.latLng.lng());
      //   }}
      onClick={onMapClick}
    >
      {isMarkerShown && currLocInfo && (
        <>
          <Marker
            icon={{
              url: "/images/pinnormal.png",
              scaledSize: {width: 35, height: 40},
            }}
            position={{
              lat: parseFloat(currLocInfo.lat),
              lng: parseFloat(currLocInfo.lng),
            }}
          />
          {/* {markerPosition && (
            <Marker
              icon={{
                url: '/images/pinnormal.png',
                scaledSize: { width: 35, height: 40 },
              }}
              position={markerPosition}
            />
          )} */}
        </>
      )}
    </GoogleMap>
  );
});
