/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import ApiService from "../api/ApiService";
import config from "../config";
import ReactLoading from "react-loading";

import Default from "../layout/Default";
import QRCode from "react-qr-code";

const headerBlackLogo = "/images/logos/logo-black-new.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(249, 127, 102)",
    },
    secondary: {
      main: "rgb(249, 127, 102)",
    },
  },
});

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function DownloadApp(props) {
  const [loading, setLoading] = useState(false);
  const [isSuccessfull, setIsSuccessfull] = useState(false);

  const [userDetails, setUserDetails] = useState(null);
  const [afterData, setAfterData] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);

  const [isUnauthorized, setIsUnauthorized] = useState(null);

  let query = useQuery();
  let params = useParams();
  useEffect(() => {
    setTimeout(() => {
      generateBranchQRCode();
    }, 500);
    checkIfVerified();
    document.getElementById("user-main-cont-id").classList.add("bg-white");

    document.querySelector("body").classList.add("bg-white");
  }, []);
  const checkIfVerified = async () => {
    console.log("params", params);
    if (params && params.id) {
      setLoading(true);
      try {
        const responseOfDive = await ApiService.getUserDetailsFromId({
          userId: params.id,
        });
        setIsUnauthorized(false);
        setLoading(false);
        if (responseOfDive && responseOfDive.data) {
          // setDiveDetails(responseOfDive.data);
          setUserDetails(responseOfDive.data);
          setIsSuccessfull(true);
        } else {
          setIsSuccessfull(false);
        }
      } catch (error) {
        setLoading(false);
        setIsUnauthorized(true);
        setIsSuccessfull(false);
      }
    } else {
      // alert("Unauthorized Request");
      setLoading(false);
      setIsUnauthorized(true);
      setIsSuccessfull(false);
    }
  };

  const renderNotification = () => (
    <>
      {/* <div className="flex flex-1 py-6 px-2 flex-row notify items-center">
        <div className="flex flex-1 notify-text">
          Quin Favre is requesting you to validate this dive log
        </div>
        <div className="flex flex-row">
          <button className="btn-lite btn-lite-txt">Decline</button>

          <button className="btn-orange btn-orange-txt">Validate</button>
        </div>
      </div> */}
    </>
  );

  const generateBranchQRCode = async () => {
    var linkData = {
      campaign: "web share",
      feature: "download app",
      stage: "generate deeplink",
      data: {
        $og_title: "Download App",
        $og_description: `Download the Buddy app.`,
        $canonical_identifier: `download`,
      },
    };
    branch.link(linkData, function (err, link) {
      console.log("link", link);
      setQrCodeData({
        type: "DOWNLOAD_APP",
        title: `Download app`,
        shareLink: config.REACT_APP_BRANCH_DOWNLOAD_APP_URL,
      });
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <div id="user-main-cont-id" className="dive-main-cont flex-1 h-full">
        <div className="main-site-wrapper-wide h-full flex-col flex">
          <Default logoSrc={headerBlackLogo} showQRCode={false}>
            {renderNotification()}
            {loading ? (
              <div className="flex justify-center items-center h-96">
                <ReactLoading
                  type={"bubbles"}
                  color={"#fff"}
                  width={50}
                  height={50}
                />
              </div>
            ) : isSuccessfull && afterData ? (
              <div className="dive-details-cont flex flex-col pl-3 pr-3 justify-center items-center ">
                <div className="h-20 w-20 mt-7 mb-7">
                  <svg
                    fill="#0DCE89"
                    version="1.1"
                    id="lni_lni-checkmark-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 64 64"
                  >
                    <g>
                      <g>
                        <path
                          d="M32,62.8C15,62.8,1.3,49,1.3,32C1.3,15,15,1.3,32,1.3C49,1.3,62.8,15,62.8,32C62.8,49,49,62.8,32,62.8z M32,4.8
              C17,4.8,4.8,17,4.8,32C4.8,47,17,59.3,32,59.3C47,59.3,59.3,47,59.3,32C59.3,17,47,4.8,32,4.8z"
                        />
                      </g>
                      <g>
                        <path
                          d="M28.6,39c-0.7,0-1.3-0.2-1.9-0.7l-6.6-6.4c-0.7-0.7-0.7-1.8,0-2.5c0.7-0.7,1.8-0.7,2.5,0l6,5.9l12.8-12.4
              c0.7-0.7,1.8-0.7,2.5,0c0.7,0.7,0.7,1.8,0,2.5l-13.3,13C29.9,38.8,29.2,39,28.6,39z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="dive-details-title pb-10 border-b border-white text-center">
                  Your request is sent to Buddy Admin for validation! Thanks for
                  validating, you can now close the window
                </div>
              </div>
            ) : (
              <div className="flex  justify-center items-center flex-1">
                <div className="user-details-data m-10">
                  <div className="user-detail-profile-pic">
                    <img
                      src="/images/App-icon.png"
                      className=" dive-details-top-img  h-[156px] w-[156px] m-auto"
                      alt="app icon"
                    />
                  </div>
                  <div className="user-detail-profile-name user-detail-txt font-bold  text-2xl py-10">
                    {/* {userDetails ? ( */}
                    <>
                      <div className="dive-details-top-title-cont  ">
                        <div className="user-details-top-title text-black font-athleticsBold text-4xl flex flex-col">
                          <span>Diving is better</span> <span>together</span>
                        </div>
                      </div>
                      <div className="user-bio text-weak sm:w-full md:w-[438px]  text-[17px] pt-5 leading-6 hidden md:block">
                        Scan the code below with your phone to download the
                        Buddy app. Available for Android and iOS.
                      </div>
                      <div className="user-bio text-weak sm:w-full md:w-[438px]  text-[17px] pt-5 leading-6 md:hidden block ">
                        Download the Buddy app.
                      </div>
                    </>
                  </div>
                  <div className="user-detail-logo user-detail-txt hidden md:block">
                    {qrCodeData && qrCodeData.shareLink && (
                      <QRCode
                        className="user-detail-logo-img-white w-52 h-52 m-auto bg-white p-6 rounded-xl border-2 border-gray"
                        value={qrCodeData?.shareLink}
                        size={200}
                      />
                    )}
                  </div>
                  <div class="user-detail-logo user-detail-txt md:hidden block ">
                    <div>
                      <button
                        onClick={() => {}}
                        className="text-black border-2 border-gray rounded-full px-5 py-[18px] text-base font-basisGrotesqueMedium"
                      >
                        Download for iOS
                      </button>
                    </div>
                    <div className="pt-3">
                      <button
                        onClick={() => {}}
                        className="text-black border-2 border-gray rounded-full px-5 py-[18px] text-base font-basisGrotesqueMedium "
                      >
                        Download for Android
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Default>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default DownloadApp;
