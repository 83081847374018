import axios from 'axios';
import config from '../config';

const api = axios.create({
  baseURL: config.REACT_APP_API_URL,
  headers: { Accept: 'application/json' },
});
//test
async function apiMethod(method, url, data) {
  return new Promise((resolve, reject) => {
    api[method.toLowerCase()](url, data)
      .then(async (res) => {
        console.log('[RESPONSE_API res]', res.ok);
        if (!res) {
          if (
            res.data &&
            res.data.data &&
            res.data.code &&
            res.data.code === 'INVALID_TOKEN'
          ) {
            // getStore().dispatch(logoutUser());
            // logout user here
            return reject(res.data);
          }
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch(reject);
  });
}

export default {
  get: (url) => apiMethod('get', url),
  post: (url, data) => apiMethod('post', url, data),
  put: (url, data) => apiMethod('put', url, data),
  delete: (url) => apiMethod('delete', url, null),
};
